import React, { useEffect } from "react";
import { BsArrowDown, BsArrowRight } from "react-icons/bs";
import Banner from "assets/images/industries/bannerbg.webp";
import applogo from "assets/images/industries/applogo.webp";
import contactgroup from "assets/images/industries/contactgroup.webp";
import beat from "assets/images/industries/beat.png";
import section_one from "assets/images/industries/section_one.png";
import section_two from "assets/images/industries/section_two.png";
import brain from "assets/images/industries/brain.png";
import coaching from "assets/images/industries/coaching.png";
import {
    Advisory,
    CaseStudies,
    ContactUsCard,
    ContentHub,
    IndustrySlider,
    TechnologyService,
    Trainers,
} from "components";
import SubtractWhite from "assets/images/Subtract-white.png";
import { scrollToContent } from "helpers/ScrollToContent";
import { useLocation } from "react-router-dom";
import { RequestModal } from "./RequestModal";

const Industries = () => {
    const [expanded, setExpanded] = React.useState(false);
    const modalRef = React.useRef(null);
    const { state } = useLocation();
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (state?.caseStudy) {
            scrollToContent("caseStudies", 1500);
        } else {
            window.scrollTo(0, 0);
        }
    }, []);

    const boxShadow = {
        boxShadow: "0 1px 15px 1px rgba(0,0,0,.08), 0 1px 6px rgba(0,0,0,.08)",
    };

    return (
        <>
            <div className="pb-28 -mt-[50px]">
                <div className="lg:bg-transparent md:bg-transparent bg-black">
                    <div className="lg:hidden md:hidden">
                        <img src={Banner} alt="Banner" />
                    </div>
                    <div className="lg:h-[90vh] md:h-[100vh] IndustriesBanner relative">
                        <div className="flex relative z-10 flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                            <p className="text-[#FBE4D3] Poppins font-normal">
                                Growth Solutions
                            </p>
                            <div className="w-full max-w-5xl">
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Boost Your Pharma & Biotech
                                </p>
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Revenue With Data-Powered Solution
                                </p>
                            </div>
                            <p className="white Poppins w-full max-w-3xl font-normal text-lg">
                                We don't just connect you with Pharma buyers; we
                                know the Pharma industry inside and out, and
                                we're here to be your trusted ally.
                            </p>
                            <div className="h-px w-full max-w-3xl bg-white mt-10" />
                            <div className="grid w-full max-w-3xl lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                <button
                                    onClick={() =>
                                        scrollToContent("contentSection", 1000)
                                    }
                                    className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary"
                                >
                                    <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                    <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                        Discover <BsArrowDown size={23} />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                            <img
                                src={SubtractWhite}
                                alt="Subtract"
                                className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                            />
                        </div>
                    </div>
                </div>
                <div className="container relative mt-20" id="contentSection">
                    <div className="grid grid-cols-12 px-10 lg:gap-10">
                        <div className="xl:col-span-3 lg:block hidden col-span-12">
                            <img src={beat} alt="beat" />
                        </div>
                        <div className="xl:col-span-9 col-span-12 relative">
                            <div className="space-y-9">
                                <div className="lg:w-60 w-48 h-16 mb-2">
                                    <img
                                        src={applogo}
                                        className="w-full h-full object-contain"
                                        alt="applogo"
                                    />
                                </div>
                                <h1 className="capitalize Poppins font-light text-new-color lg:text-5xl text-3xl">
                                    Beat Your Competition
                                </h1>
                                <div className="space-y-5">
                                    <p className="text-[#5ABD77] Poppins lg:text-3xl text-xl font-light">
                                        Connect with Biotech and Pharma
                                        decision-makers for targeted B2B exposure to
                                        the right buyers.
                                    </p>
                                    <p className="Poppins text-lg text-new-color">
                                        Forge game-changing partnerships in the
                                        pharma and biotech sectors. Our extensive
                                        network opens doors to decision-makers who
                                        can drive your growth and innovation.
                                    </p>
                                    <p className="Poppins text-lg text-new-color">
                                        Tailored Prospect Lists Delivered to Your
                                        Inbox
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute lg:block hidden right-1/4 top-0">
                        <svg
                            width="108"
                            height="108"
                            viewBox="0 0 108 108"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_2_811)">
                                <path
                                    d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                    fill="#FFEFE5"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2_811">
                                    <rect
                                        width="81"
                                        height="81"
                                        fill="white"
                                        transform="translate(0 33.8882) rotate(-24.7319)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="absolute lg:block hidden left-0 -top-20">
                        <svg width="177" height="145" viewBox="0 0 177 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="72" cy="72.8877" r="57" fill="white" stroke="#FFEFE5" stroke-width="30"/>
                            <circle cx="157.5" cy="77.3877" r="19.5" fill="#B5E2FF"/>
                        </svg>
                    </div>
                    <div className="absolute lg:block hidden right-0 -top-20">
                        <svg width="250" height="250" viewBox="0 0 312 312" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M171.032 23.9866L135.946 105.246L54.6866 70.1607C34.3716 61.3893 12.5569 70.047 3.78549 90.3619C-4.98591 110.677 3.67174 132.492 23.9867 141.263L105.246 176.349L70.1608 257.608C61.3894 277.923 70.047 299.738 90.362 308.509C110.677 317.281 132.492 308.623 141.263 288.308L176.349 207.048L257.608 242.134C277.923 250.905 299.738 242.248 308.509 221.933C317.281 201.618 308.623 179.803 288.308 171.032L207.049 135.946L242.134 54.6865C250.906 34.3716 242.248 12.5569 221.933 3.78545C201.618 -4.98595 179.803 3.67168 171.032 23.9866Z" fill="#5ABD77" fill-opacity="0.2"/>
                        </svg>
                    </div>
                </div>
                <div className="bg-[#5ABD77]/20 pt-20 pb-52 mt-20 overflow-hidden">
                    <div className="container px-10 space-y-5">
                        <p className="lg:text-5xl capitalize text-3xl Poppins font-light text-new-color text-center">
                            Why waste time on generic databases?
                        </p>
                        <p className="text-lg Poppins font-normal text-new-color text-center">
                            Tell us your ideal customer profile, and we'll
                            handpick the perfect prospects from our curated
                            database.
                        </p>
                    </div>
                    <div className="grid xl:grid-cols-2 grid-cols-1 lg:mt-28 mt-10 relative">
                        <div className="flex flex-col lg:pl-10 h-max relative">
                            <div className="xl:h-[700px] xl:w-[700px] gap-y-3 flex-col flex justify-start xl:pl-20 xl:border-[24px] border-[#5ABD77]/10 rounded-full">
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg
                                            width="21"
                                            height="23"
                                            viewBox="0 0 21 23"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.915 10.08C8.1367 10.08 5.875 7.8183 5.875 5.04C5.875 2.2617 8.1367 0 10.915 0C13.6933 0 15.955 2.2617 15.955 5.04C15.955 7.8183 13.6933 10.08 10.915 10.08Z"
                                                fill="#5ABD77"
                                            />
                                            <path
                                                d="M17.233 22.0498H4.59521C2.52251 22.0498 0.834106 20.3614 0.834106 18.2887C0.834106 14.4583 3.95261 11.3398 7.78301 11.3398H14.0452C17.8756 11.3398 20.9941 14.4583 20.9941 18.2887C20.9941 20.3614 19.3057 22.0498 17.233 22.0498Z"
                                                fill="#5ABD77"
                                            />
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Rich Company Profiles:
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Company Name & Ownership Details
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Digital Footprint (Website &
                                            LinkedIn)
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Geographic Presence
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Company Scale (Employee Count)
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Specialization (Tech & Disease Focus
                                            Areas)
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg
                                            width="20"
                                            height="16"
                                            viewBox="0 0 20 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.65116 7.64903L1.16712 0.111335C1.35677 0.0386847 1.55868 0.000944415 1.76152 0H17.8634C18.0663 0.000943691 18.2682 0.0386847 18.4578 0.111335L9.96342 7.65941C9.87096 7.73206 9.73891 7.72828 9.65116 7.64903ZM19.524 1.17749L10.9796 8.80672C10.6617 9.09731 10.2437 9.25584 9.81251 9.24922C9.37849 9.25299 8.95959 9.09448 8.63501 8.80672L0.11039 1.17749C0.0396266 1.3643 0.00188642 1.56148 0 1.76153V13.8384C0.00566118 14.8093 0.790652 15.5943 1.76154 15.6H17.8635C18.8343 15.5943 19.6193 14.8093 19.625 13.8384V1.76153C19.6222 1.56339 19.5882 1.3662 19.524 1.17749Z"
                                                fill="#5ABD77"
                                            />
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Decision-Maker Intel:
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Name & Title of Key Contact(s)
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Verified Email Addresses
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Professional LinkedIn Profiles
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg
                                            width="22"
                                            height="16"
                                            viewBox="0 0 22 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M20.7094 8.17925C20.4345 7.90389 20.1293 7.66378 19.8003 7.46274C19.7508 5.8982 18.9342 4.46812 17.6364 3.67343C16.3386 2.87974 14.7393 2.83115 13.3993 3.5448C12.5855 1.70969 10.9503 0.413834 9.03321 0.0823462C7.11589 -0.248288 5.16567 0.429177 3.82386 1.8908C2.48303 3.35337 1.9258 5.41155 2.33545 7.38657C1.17059 8.02972 0.344807 9.18264 0.0854535 10.5271C-0.173008 11.8715 0.161523 13.2663 0.998281 14.3268C1.83413 15.3864 3.08423 16.0009 4.40314 16H17.6007C18.7684 15.9981 19.8874 15.5141 20.7123 14.6546C21.5372 13.7952 22.0009 12.6299 22 11.4161C21.9991 10.2022 21.5352 9.03785 20.7094 8.17925ZM13.7366 10.9995L11.537 13.4692C11.3996 13.6245 11.2053 13.7131 11.0018 13.7131C10.7984 13.7131 10.6041 13.6245 10.4666 13.4692L8.267 10.9995C7.99113 10.6898 8.00762 10.2058 8.30366 9.91707C8.44663 9.77986 8.63544 9.7084 8.82973 9.71698C9.02403 9.72555 9.20642 9.81416 9.33748 9.9628L10.2687 11.0071V5.90767C10.2687 5.48651 10.5968 5.14541 11.0019 5.14541C11.407 5.14541 11.7351 5.48651 11.7351 5.90767V11.0071L12.6662 9.9628C12.7973 9.81416 12.9797 9.72555 13.174 9.71698C13.3683 9.7084 13.5571 9.77986 13.7001 9.91707C13.9961 10.2058 14.0125 10.6898 13.7366 10.9995Z"
                                                fill="#5ABD77"
                                            />
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Delivery:
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="text-new-color font-normal Poppins text-lg  ml-4">
                                            Choose your preferred format - sleek
                                            .csv or familiar Excel.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <img
                                src={section_two}
                                alt="section_two"
                                className="xl:-mt-56 mt-5 ml-24 xl:block hidden xl:w-full xl:h-[293px]"
                            />
                        </div>
                        <div className="absolute -z-20 h-[851px] w-[851px] rounded-full bg-[#5ABD77]/20 -right-[30%] -top-[30.5%] xl:block hidden" />
                        <div className="h-max">
                            <div className="xl:-ml-16 pt-5 xl:block hidden">
                                <img src={section_one} alt="section_one" />
                            </div>
                            <div className="flex flex-col xl:mt-48 mt-10 gap-y-8 lg:ml-32">
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg
                                            width="29"
                                            height="17"
                                            viewBox="0 0 29 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14.3874 0C5.16765 0 0.677734 8.5 0.677734 8.5C0.677734 8.5 5.64749 17 14.3874 17C23.1273 17 28.0971 8.5 28.0971 8.5C28.0971 8.5 23.6072 0 14.3874 0ZM14.3874 15.2177C10.6858 15.2177 7.66967 12.2016 7.66967 8.5C7.66967 4.79839 10.6858 1.78226 14.3874 1.78226C18.089 1.78226 21.1052 4.79839 21.1052 8.5C21.1052 12.2016 18.089 15.2177 14.3874 15.2177Z"
                                                fill="#5ABD77"
                                            />
                                            <path
                                                d="M14.3874 5.55225C12.7422 5.55225 11.4398 6.88894 11.4398 8.49983C11.4398 10.145 12.7765 11.4474 14.3874 11.4474C15.9983 11.4474 17.335 10.1107 17.335 8.49983C17.335 6.85466 16.0326 5.55225 14.3874 5.55225Z"
                                                fill="#5ABD77"
                                            />
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Hierarchical Insights:
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Explore contacts across various
                                            organizational levels to tailor your
                                            approach.
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg
                                            width="24"
                                            height="23"
                                            viewBox="0 0 24 23"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.4156 0.880903L15.901 5.92097C16.1319 6.38746 16.5763 6.71075 17.09 6.7856L22.6472 7.59382H22.6482C23.2422 7.68061 23.7365 8.09719 23.9229 8.66999C24.1083 9.2417 23.9533 9.86982 23.523 10.2897L19.5017 14.2136C19.1288 14.5759 18.9598 15.0988 19.0476 15.6119L19.9971 21.1522C20.0979 21.7445 19.8551 22.3444 19.3684 22.6981C18.8828 23.0517 18.2379 23.0984 17.7057 22.8185L12.7349 20.2029V20.2018C12.2742 19.9599 11.7247 19.9599 11.2651 20.2018L6.29431 22.8185C5.76211 23.0984 5.11718 23.0517 4.63159 22.6981C4.14492 22.3444 3.90212 21.7445 4.00293 21.1522L4.95244 15.6119C5.04024 15.0999 4.87115 14.5759 4.49829 14.2136L0.477037 10.2897C0.0467193 9.86982 -0.108282 9.2417 0.0770757 8.66999C0.26351 8.09719 0.757764 7.68061 1.35177 7.59382L6.90888 6.7856H6.90996C7.42375 6.71074 7.86813 6.38746 8.09902 5.92097L10.5844 0.880901C10.85 0.341735 11.3984 0 12 0C12.6005 0 13.15 0.341737 13.4156 0.880903Z"
                                                fill="#5ABD77"
                                            />
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Value-Added Features:
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Regular database updates ensure
                                            you're always targeting active
                                            prospects.
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Compliance with data protection
                                            regulations for peace of mind
                                        </li>
                                        <li className="text-new-color font-normal Poppins text-lg ml-4 max-w-[520px]">
                                            Optional: Custom research for
                                            ultra-specific targeting needs
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg
                                            width="25"
                                            height="24"
                                            viewBox="0 0 25 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M25 12C25 10.7832 22.5263 9.96316 22.1579 8.85216C21.7895 7.7147 23.3158 5.57206 22.6053 4.61977C21.8947 3.64103 19.4211 4.46105 18.4474 3.74684C17.5 3.05907 17.4737 0.413831 16.3421 0.0434968C15.2368 -0.326837 13.7105 1.78936 12.4737 1.78936C11.2632 1.78936 9.71053 -0.326837 8.60526 0.0434968C7.47368 0.413831 7.44737 3.03262 6.5 3.74684C5.52632 4.46105 3.05263 3.64103 2.34211 4.61977C1.65789 5.57206 3.15789 7.7147 2.78947 8.85216C2.47368 9.96316 0 10.7832 0 12C0 13.2168 2.47368 14.0368 2.84211 15.1478C3.21053 16.2853 1.68421 18.4279 2.39474 19.3802C3.10526 20.359 5.57895 19.5389 6.55263 20.2532C7.5 20.9409 7.52632 23.5862 8.6579 23.9565C9.76316 24.3268 11.2895 22.2106 12.5263 22.2106C13.7368 22.2106 15.2895 24.3268 16.3947 23.9565C17.5263 23.5862 17.5526 20.9674 18.5 20.2532C19.4737 19.5389 21.9474 20.359 22.6579 19.3802C23.3421 18.4279 21.8421 16.2853 22.2105 15.1478C22.5263 14.0368 25 13.2168 25 12Z"
                                                fill="#5ABD77"
                                            />
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Turn Data into Deals:
                                        </p>
                                    </div>
                                    <ul>
                                        <li className="text-new-color font-normal Poppins w-full max-w-2xl text-lg ml-4">
                                            Armed with our precisely curated
                                            lists, watch your conversion rates
                                            soar as you connect with the right
                                            decision-makers in your target
                                            market.
                                        </li>
                                    </ul>
                                </div>
                                <p className="text-new-color font-normal Poppins text-lg">
                                    Ready to revolutionize your biotech and
                                    pharma outreach? Let's get started!
                                </p>
                                <button
                                    onClick={() => modalRef.current?.open()}
                                    className="flex bg-[#5ABD77] px-4 -mt-3 py-2 hover:opacity-80 text-lg Poppins text-white justify-between items-center w-48 rounded-full "
                                >
                                    Request Quote
                                    <BsArrowRight size={20} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-7xl relative -mt-[150px] mx-auto">
                    <div className="industies-biotech lg:h-[300px]">
                        <div className="h-full bg-white/10 w-full lg:px-20 py-10 px-10 flex flex-col gap-5 justify-center">
                            <p className="text-center Poppins text-3xl white font-light">
                                "8 out of 10 Biotech & Pharma Lifesciences
                                Industries Suppliers are <br /> currently
                                missing out on effective marketing
                                strategies
                            </p>
                            <p className="text-center white w-full max-w-4xl font-normal Poppins text-lg mx-auto">
                                It's time to step into the spotlight and
                                seize your opportunity to be among the
                                exclusive 20% that are revolutionizing their
                                approach. With our transformative solutions,
                                you can break free from the norm, outshine
                                the competition, and position yourself as a
                                trailblazer in the industry.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg--lightBlue pb-10 px-5 pt-[150px] -mt-[150px]">
                    <div className="flex lg:flex-nowrap flex-wrap container gap-5 mt-36 mx-auto relative">
                        <div className="absolute lg:block hidden right-[30%] -top-32">
                            <svg width="200" height="200" viewBox="0 0 312 312" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M171.032 23.9864L135.946 105.246L54.6866 70.1605C34.3716 61.3891 12.5569 70.0467 3.78549 90.3617C-4.98591 110.677 3.67174 132.491 23.9867 141.263L105.246 176.348L70.1608 257.608C61.3894 277.923 70.047 299.738 90.362 308.509C110.677 317.281 132.492 308.623 141.263 288.308L176.349 207.048L257.608 242.134C277.923 250.905 299.738 242.248 308.509 221.933C317.281 201.618 308.623 179.803 288.308 171.032L207.049 135.946L242.134 54.6863C250.906 34.3713 242.248 12.5566 221.933 3.78521C201.618 -4.98619 179.803 3.67144 171.032 23.9864Z" fill="#5ABD77" fill-opacity="0.2"/>
                            </svg>
                        </div>
                        <div className="absolute lg:block hidden right-[50%] -top-20">
                            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.1999 3.45217L32.111 18.9081L16.6551 3.81914C12.7911 0.0469077 7.06396 0.115713 3.29173 3.97969C-0.480498 7.84366 -0.411693 13.5708 3.45228 17.343L18.9082 32.432L3.81924 47.8878C0.0470109 51.7518 0.115817 57.479 3.97979 61.2512C7.84376 65.0234 13.5709 64.9546 17.3431 61.0907L32.4321 45.6348L47.888 60.7237C51.7519 64.4959 57.4791 64.4271 61.2513 60.5631C65.0235 56.6992 64.9547 50.972 61.0908 47.1998L45.6349 32.1109L60.7238 16.655C64.496 12.791 64.4272 7.06386 60.5632 3.29163C56.6993 -0.4806 50.9721 -0.411799 47.1999 3.45217Z" fill="#FF6600" fill-opacity="0.1"/>
                            </svg>
                        </div>
                        <div>
                            <img src={brain} alt="brain" className="-mt-28" />
                        </div>
                        <div className="w-full max-w-3xl lg:mt-20 mt-10 mx-auto space-y-5">
                            <h1 className="Poppins lg:text-5xl text-4xl font-light">
                                Our Exclusive Pharma Solutions
                            </h1>
                            <p className="text-3xl text-[#5ABD77] Poppins font-light">
                                Running out of time and expertise for your
                                Biotech or Pharma content marketing?
                            </p>
                            <p className="text-lg Poppins font-medium text-new-color">
                                We get it - building a strong digital presence
                                is tough. That's where we step in. We research
                                and create content specifically for your
                                industry. Let us boost your marketing without
                                the extra effort or resources.
                            </p>
                            <p className="text-lg Poppins font-medium text-new-color">
                                Achieve more, stress less with our help.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="container mt-20 lg:space-y-20 space-y-10"
                    id="scrollToDiscover"
                >
                    <ContentHub
                        boxShadow={boxShadow}
                        expanded={expanded}
                        handleChange={handleChange}
                    />
                    <Advisory
                        boxShadow={boxShadow}
                        expanded={expanded}
                        handleChange={handleChange}
                    />
                </div>
                <div
                    className="py-10 mt-20 bg-[#233862] relative"
                    id="caseStudies"
                >
                    <div className="absolute bottom-[17%] right-0 lg:block hidden">
                        <svg
                            width="170"
                            height="488"
                            viewBox="0 0 339 488"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M487 244.388C487 378.869 377.981 487.888 243.5 487.888C109.019 487.888 0 378.869 0 244.388C0 109.906 109.019 0.887695 243.5 0.887695C377.981 0.887695 487 109.906 487 244.388Z"
                                fill="#FFFAEF"
                                fillOpacity="0.1"
                            />
                        </svg>
                    </div>
                    <div className="absolute -top-44 left-[15%] lg:block hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="268"
                            height="268"
                            viewBox="0 0 268 268"
                            fill="none"
                        >
                            <path
                                d="M268 134C268 208.006 208.006 268 134 268C59.9938 268 0 208.006 0 134C0 59.9938 59.9938 0 134 0C208.006 0 268 59.9938 268 134Z"
                                fill="#FFFAEF"
                                fillOpacity="0.1"
                            />
                        </svg>
                    </div>
                    <CaseStudies />
                </div>
                <div className="mt-20 container">
                    <TechnologyService />
                </div>
                <div className="bg--lightBlue py-20 mt-20">
                    <div className="container mb-20">
                        <div className="grid grid-cols-12 lg:gap-10 place-items-end relative">
                            <div className="absolute lg:block hidden right-[50%] -top-10">
                                <svg width="200" height="200" viewBox="0 0 312 312" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M171.032 23.9864L135.946 105.246L54.6866 70.1605C34.3716 61.3891 12.5569 70.0467 3.78549 90.3617C-4.98591 110.677 3.67174 132.491 23.9867 141.263L105.246 176.348L70.1608 257.608C61.3894 277.923 70.047 299.738 90.362 308.509C110.677 317.281 132.492 308.623 141.263 288.308L176.349 207.048L257.608 242.134C277.923 250.905 299.738 242.248 308.509 221.933C317.281 201.618 308.623 179.803 288.308 171.032L207.049 135.946L242.134 54.6863C250.906 34.3713 242.248 12.5566 221.933 3.78521C201.618 -4.98619 179.803 3.67144 171.032 23.9864Z" fill="#5ABD77" fill-opacity="0.2"/>
                                </svg>
                            </div>
                            <div className="lg:col-span-4 lg:block hidden col-span-12">
                                <img src={coaching} alt="coaching" />
                            </div>
                            <div className="lg:col-span-8 col-span-12 space-y-5">
                                <p className="Poppins text-new-color lg:text-5xl text-3xl font-light">Expert Coaching:</p>
                                <p className="Poppins text-[#5ABD77] lg:text-3xl text-xl font-light">Refine Your Competitive Edge in Biopharma</p>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg width="29" height="19" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.98418 9.03026L13.5905 12.3832C14.1752 12.6192 14.8272 12.6192 15.412 12.3832L24.0119 9.03638V14.8509C24.0193 15.2917 23.8053 15.7063 23.442 15.9549C21.5309 17.291 18.2375 18.1927 14.4949 18.1927C10.7524 18.1927 7.45893 17.3073 5.54784 15.9549C5.18452 15.7064 4.96929 15.2917 4.97799 14.8509V9.03052L4.98418 9.03026ZM2.43606 13.1313V7.37695L13.8033 11.7987C14.2512 11.9811 14.7514 11.9811 15.1993 11.7987L28.577 6.59399C28.832 6.49159 29 6.24308 29 5.96711C29 5.69114 28.832 5.44262 28.577 5.34024L15.1993 0.135493V0.136741C14.7514 -0.0455805 14.2512 -0.0455805 13.8033 0.136741L0.423041 5.35054C0.166737 5.45294 0 5.70145 0 5.97742C0 6.25339 0.166721 6.50192 0.423041 6.6043L1.81779 7.14752V13.1318C1.39974 13.2766 1.12104 13.6725 1.12354 14.117V17.7036C1.1198 18.0633 1.40224 18.3592 1.76057 18.3717H2.47101C2.82934 18.3592 3.11179 18.0633 3.10804 17.7036V14.117C3.11302 13.6737 2.8368 13.2779 2.42 13.1318L2.43606 13.1313Z" fill="#5ABD77"/>
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Pharma Procurement Masterclasses:
                                        </p>
                                    </div>
                                    <p className="text-new-color font-normal Poppins text-lg ml-10 max-w-[600px]">
                                        Led by Pharma Procurement experts, offering insider knowledge on winning more business.
                                    </p>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.87543 18.2253C2.4809 18.4337 1.99341 18.2948 1.7613 17.9011L0.113478 15.0758C-0.118624 14.6821 0.0206215 14.1958 0.391961 13.9642L1.52928 13.2694C1.90062 13.0379 2.22558 12.482 2.22558 12.042V9.95782C2.22558 9.5178 1.92381 8.96199 1.52928 8.73041L0.391961 8.03567C0.0206176 7.80409 -0.118624 7.29465 0.113478 6.92405L1.76138 4.07562C1.99348 3.68198 2.48088 3.54295 2.87551 3.75139L4.05921 4.37662C4.45374 4.58506 5.08048 4.58506 5.45182 4.35348L7.26225 3.31137C7.65679 3.07979 7.95856 2.54712 7.98175 2.1072L8.00494 0.810527C8.00494 0.370508 8.37629 0 8.84049 0H12.1131C12.5541 0 12.9255 0.370508 12.9487 0.810527L12.9951 2.13049C12.9951 2.57051 13.32 3.10309 13.7146 3.33466L15.525 4.37677C15.9195 4.60835 16.5463 4.60835 16.9176 4.39991L18.1013 3.77469C18.4958 3.56625 18.9833 3.70518 19.2154 4.09892L20.8633 6.94734C21.0954 7.34099 20.9562 7.82738 20.5848 8.05896L19.4475 8.7537C19.0762 8.98528 18.7512 9.54109 18.7744 9.98111V12.0653C18.7744 12.5053 19.0762 13.0612 19.4707 13.2927L20.608 13.9875C20.9794 14.219 21.1186 14.7285 20.8865 15.0991L19.2386 17.9475C19.0065 18.3412 18.5191 18.4802 18.1245 18.2717L16.964 17.6465C16.5694 17.4381 15.9427 17.4381 15.5714 17.6697L13.7609 18.7118C13.3664 18.9433 13.0646 19.476 13.0414 19.9159L12.9719 21.1895C12.9487 21.6295 12.6005 22 12.1363 22H8.86368C8.42266 22 8.05132 21.6295 8.02813 21.1895L7.98174 19.8695C7.98174 19.4295 7.65678 18.8969 7.26224 18.6653L5.45181 17.6232C5.05728 17.3916 4.43054 17.3916 4.0592 17.6001L2.87543 18.2253ZM8.07451 15.1916C10.3955 16.5348 13.3432 15.7243 14.6894 13.4316C16.0356 11.1158 15.2233 8.17477 12.9255 6.83161C10.6044 5.48841 7.65675 6.29894 6.31056 8.59159C4.96437 10.8842 5.75349 13.8484 8.07451 15.1916Z" fill="#5ABD77"/>
                                        </svg>

                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Customized Training Programs: 
                                        </p>
                                    </div>
                                    <p className="text-new-color font-normal ml-9 Poppins text-lg">
                                        Tailored to your team's specific needs and challenges.
                                    </p>
                                </div>
                                <div>
                                    <div className="flex items-center gap-x-2">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.0028 14.5709C18.9965 14.5709 18.9911 14.5709 18.9849 14.57C16.1907 14.4263 15.0597 12.8477 14.6455 11.9754H7.1525C4.17956 11.9754 1.76111 9.55677 1.76111 6.58402C1.76021 3.61108 4.17879 1.19263 7.15193 1.19263H15.848C18.821 1.19263 21.2394 3.61127 21.2394 6.58402C21.2394 8.87769 19.7624 10.9324 17.6133 11.6781C17.8038 13.0941 19.1649 13.9153 19.1793 13.9243C19.3167 14.006 19.3814 14.1705 19.3347 14.3241C19.2907 14.4714 19.1541 14.5712 19.0023 14.5712L19.0028 14.5709ZM7.15169 1.88851C4.56227 1.88851 2.45547 3.99531 2.45547 6.58473C2.45547 9.17414 4.56227 11.2809 7.15169 11.2809H14.8718C15.0138 11.2809 15.1413 11.3672 15.1944 11.4993C15.419 12.0599 16.0946 13.253 17.861 13.7067C17.3929 13.1856 16.9248 12.4228 16.897 11.4418C16.8925 11.2836 16.9958 11.1417 17.1486 11.0977C19.1476 10.5227 20.5437 8.66753 20.5437 6.58584C20.5437 3.99642 18.4369 1.88962 15.8475 1.88962H7.15142L7.15169 1.88851Z" fill="#5ABD77"/>
                                            <path d="M7.15166 1.88855C4.56224 1.88855 2.45544 3.99535 2.45544 6.58477C2.45544 9.17418 4.56224 11.281 7.15166 11.281H14.8718C15.0138 11.281 15.1413 11.3672 15.1943 11.4993C15.419 12.0599 16.0946 13.2531 17.861 13.7067C17.3929 13.1856 16.9248 12.4229 16.897 11.4418C16.8925 11.2837 16.9958 11.1417 17.1485 11.0977C19.1476 10.5227 20.5437 8.66757 20.5437 6.58588C20.5437 3.99646 18.4369 1.88966 15.8475 1.88966H7.15139L7.15166 1.88855Z" fill="#5ABD77"/>
                                            <path d="M12.5879 12.7095H6.82717C4.78957 12.7095 3.13721 14.3617 3.13721 16.3985C3.13721 17.9752 4.13088 19.3103 5.52154 19.8377C5.45236 20.1989 5.26639 20.5008 5.06064 20.7299C4.80549 21.0156 4.55661 21.154 4.55932 21.1593C4.42096 21.2411 4.35717 21.4055 4.40389 21.5591C4.44881 21.7065 4.58537 21.8071 4.739 21.8071H4.75517C6.56642 21.7173 7.3695 20.7191 7.69476 20.0875H12.5876C14.6252 20.0875 16.2757 18.4362 16.2776 16.3995C16.2767 14.3619 14.6254 12.7105 12.5876 12.7105L12.5879 12.7095Z" fill="#5ABD77"/>
                                            <path d="M8.34016 7.8281C8.34016 7.20637 7.83704 6.70325 7.21531 6.70325C6.59358 6.70325 6.09045 7.20727 6.09045 7.8281C6.09045 8.44893 6.59358 8.95295 7.21531 8.95295C7.83704 8.95295 8.34016 8.44893 8.34016 7.8281Z" fill="#5ABD77"/>
                                            <path d="M12.4083 7.8281C12.4083 7.20637 11.9052 6.70325 11.2834 6.70325C10.6617 6.70325 10.1586 7.20727 10.1586 7.8281C10.1586 8.44893 10.6617 8.95295 11.2834 8.95295C11.9052 8.95295 12.4083 8.44893 12.4083 7.8281Z" fill="#5ABD77"/>
                                            <path d="M16.4764 7.8281C16.4764 7.20637 15.9733 6.70325 15.3515 6.70325C14.7298 6.70325 14.2267 7.20727 14.2267 7.8281C14.2267 8.44893 14.7298 8.95295 15.3515 8.95295C15.9733 8.95295 16.4764 8.44893 16.4764 7.8281Z" fill="#5ABD77"/>
                                        </svg>
                                        <p className="text-new-color font-bold Poppins text-lg">
                                            Market Access Strategy Sessions: 
                                        </p>
                                    </div>
                                    <p className="text-new-color font-normal ml-9 Poppins text-lg">
                                        One-on-one guidance to refine your pitch and negotiation tactics.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-mt-20 container">
                    <div className="grid grid-cols-12 gap-8">
                        <div
                            style={boxShadow}
                            className="flex justify-center items-center bg-white flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-5"
                        >
                            <h1 className="p-0 m-0 success-new Poppins font-semibold lg:text-[100px] text-[70px]">
                                2x
                            </h1>
                            <div className="flex-grow flex flex-col justify-start items-center">
                                <p className="text-3xl Poppins font-normal text-new-color">
                                    Increase
                                </p>
                                <p className="my-2 text-lg Poppins font-normal text-center text-new-color">
                                    in email open rate
                                </p>
                            </div>
                        </div>
                        <div
                            style={boxShadow}
                            className="flex justify-center items-center bg-white flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-5"
                        >
                            <h1 className="p-0 m-0 success-new Poppins font-semibold lg:text-[100px] text-[70px]">
                                3x
                            </h1>
                            <div className="flex-grow flex flex-col justify-start items-center">
                                <p className="text-3xl Poppins font-normal text-new-color">
                                    Increase
                                </p>
                                <p className="my-2 text-lg Poppins font-normal text-center text-new-color">
                                    in organic website traffic/LinkedIn
                                    Followers
                                </p>
                            </div>
                        </div>
                        <div
                            style={boxShadow}
                            className="flex justify-center items-center bg-white flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-5"
                        >
                            <h1 className="p-0 m-0 success-new Poppins font-semibold lg:text-[100px] text-[70px]">
                                2x
                            </h1>
                            <div className="flex-grow flex flex-col justify-start items-center">
                                <p className="text-3xl Poppins font-normal text-new-color">
                                    Increase
                                </p>
                                <p className="my-2 text-lg Poppins font-normal text-center text-new-color">
                                    in brand visibility
                                </p>
                            </div>
                        </div>
                        <div
                            style={boxShadow}
                            className="flex justify-center items-center bg-white flex-col lg:col-span-3 md:col-span-6 col-span-12 rounded-[20px] w-full h-full p-5"
                        >
                            <h1 className="text-3xl font-light text-new-color Poppins text-center my-2">
                                Enhanced Networking Opportunities
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="mt-28 container">
                    <Trainers title={'Pharma Industry Leaders'} textColor={'text-[#5ABD77]'} isBio={true} />
                </div>
                <div className="pt-10 mt-20 bg--lightBlue">
                    <h1 className="text-center lg:text-5xl text-4xl Poppins font-light text-new-color pb-3">
                        Procurement League’s Potential
                    </h1>
                    <div className="container">
                        <div className="lg:w-1/3 lg:h-1/4 md:w-1/2 md:h-1/2 mx-auto pt-5">
                            <img
                                src={contactgroup}
                                alt="contactgroup"
                                className="w-full h-full"
                            />
                        </div>
                        <div className="flex lg:flex-row md:flex-row flex-col gap-10 justify-center mt-10">
                            <div className="text-center">
                                <h6 className="pb-3 font-light Poppins text-3xl text-new-color">
                                    Without Us
                                </h6>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Networking Challenges,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Visibility Gap,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Limited Market Reach,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Growth Limitations
                                </p>
                            </div>
                            <div className="text-center">
                                <h6 className="pb-3 font-light Poppins text-3xl text-new-color">
                                    With Us
                                </h6>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Pharma and Biotech Advancement,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Exclusive Market Insights,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Elevated Industry Presence,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Customized Solutions,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Cost-Efficient Solutions,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Wider Audience Reach,
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Community Building
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Time-saving Research Solutions
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Exclusive Insights with Unique Data
                                </p>
                                <p className="text-lg Poppins text-new-color font-normal pb-3">
                                    Unparalleled Global Coverage
                                </p>
                            </div>
                        </div>

                        <IndustrySlider />
                        <ContactUsCard type={"pharma"} />
                    </div>
                </div>
            </div>
            <RequestModal modalRef={modalRef} />
        </>
    );
};

export default Industries;
