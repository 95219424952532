import React, { useRef, useState } from "react";
import { Buttonlink, KeepChecking, NeedHelp, Signinpopup } from "components";
import ADD_PRODUCT_ACTIONS from "../../graphql/SgMutations/AddSgProductAction";
import { useMutation } from "@apollo/client";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import Auth from "../../Auth";
import { useEffect } from "react";
import { OptionsModal } from "container/SellersProfile/OptionsModal";
import { AiOutlineRise } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { Tab } from "@headlessui/react";
import { IoPerson } from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi";
import { SellersListing } from "container/SellersProfile/SellersListing";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FaBookReader } from "react-icons/fa";
import { SellersContentListing } from "container/SellersProfile/SellerContentListing";

export default function Index(props) {
	const auth = new Auth();
	const isLogin = auth.isAuthenticated();
	const modalRef = useRef(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [selectedTab, setSelectedTab] = useState(1);
    const [openModal, setOpenModal] = useState(false);
	const history = useHistory();
	const is_creator = useSelector(
		(state) => state.AuthUserReducer?.authUserInformation?.is_creator
	);
	const is_company = useSelector(
		(state) => state.AuthUserReducer?.authUserInformation?.creator?.is_company
	);

	const onLoadMore = async (data, fetchMore, refetch) => {
		setIsLoading(true);

		try {
			const result = await fetchMore({
				variables: {
					cursor: data.sgUsers.paginatorInfo.currentPage + 1,
				},
				updateQuery: (previousResult, { fetchMoreResult }) => {
					const newQuestions = fetchMoreResult.sgUsers.data;
					const pageInfo = fetchMoreResult.sgUsers.paginatorInfo;
					return newQuestions.length
						? {
								sgUsers: {
									__typename: previousResult.sgUsers.__typename,
									data: [...previousResult.sgUsers.data, ...newQuestions],
									paginatorInfo: pageInfo,
								},
						  }
						: previousResult;
				},
			});
		} catch (error) {
			console.error("Error fetching more:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const [add_products_actions] = useMutation(ADD_PRODUCT_ACTIONS);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	return (
		<>
            {openModal && (
                <Signinpopup
                    open={openModal}
                    closePopup={() => setOpenModal(false)}
                    protected={false}
                />
            )}
			<OptionsModal modalRef={modalRef} />
			<div className="container pt-10 -mt-[50px]">
				<Buttonlink
					Text="<< Back"
					onClick={() =>
						props?.history ? props.history.goBack() : props.history.go("/")
					}
					className="flex items-center justify-center px-4 bg-white rounded-full Poppins primary !text-lg"
				/>
				<div className="mt-5">
					<div className="flex lg:flex-row flex-col justify-between items-center">
						<h1 className="text-new-color font-light Poppins md:text-[50px] text-[30px]">
							Discover Unique Sellers and <br /> Their Extraordinary Services
						</h1>
						<button
							onClick={() => {
                            if (!isLogin) {
								setOpenModal(true);
                            } else if (is_creator === 2) {
								history.push({ pathname: `/seller-profile/navigation`});
                            } else {
								history.push({ pathname: `/seller-profile/profile-information`, state: is_company ? "company": "individual" })
							}}}
							className="disabled:opacity-30 flex items-center gap-2 text-white mt-10 h-12 px-3 border-0 shadow-none bg--primary Regular hover:bg--primary hover:opacity-70 focus:bg--primary rounded-md"
						>
							<AiOutlineRise size={20} />
							<span className="ml-1 truncate">
								{is_creator !== 2 || !isLogin ? "Become Influencer": "Creator Profile"}
							</span>
						</button>
					</div>
					<p className="text-lg text-new-color pt-5 Poppins">
						Experience personalized services from sellers who take the time to
						understand your needs and preferences. <br /> Maximize Efficiency,
						Minimize Cost: Unlock the secrets of Procurement Outsourcing!
					</p>
				</div>
				<div className="p-8 mt-10 border rounded-xl">
					<Tab.Group>
						<Tab.List className="flex md:flex-nowrap flex-wrap max-w-full p-2 mx-auto mb-5 bg-gray-100 lightPrimary rounded-xl">
							<Tab
								onClick={() => setSelectedTab(0)}
								className={() =>
									classNames(
										"flex-grow p-0 m-0 flex-shrink-0 bg-gray-200 Regular px-4 hover:bg--lightPrimary hover:opacity-70 cursor-pointer md:rounded-l-full h-10 flex items-center justify-center",
										selectedTab === 0
											? "bg--primary text-white"
											: "bg--lightPrimary primary"
									)
								}
							>
								Sellers
								<HiUserGroup className="ml-2" />
							</Tab>
							<Tab
								onClick={() => setSelectedTab(1)}
								className={() =>
									classNames(
										"flex-grow p-0 m-0 flex-shrink-0 bg--lightPrimary Regular px-4 hover:bg--lightPrimary hover:opacity-70 cursor-pointer md:rounded-r-full h-10 flex items-center justify-center",
										selectedTab === 1
											? "bg--primary text-white"
											: "bg--lightPrimary primary"
									)
								}
							>
								Sellers Content
								<FaBookReader className="ml-2" />
							</Tab>
						</Tab.List>
						<Tab.Panels className="mt-2">
							{selectedTab == 0 ? (
								<SellersListing
									isLoading={isLoading}
									extractValidationErrors={extractValidationErrors}
									onLoadMore={onLoadMore}
									add_products_actions={add_products_actions}
								/>
							) : (
								<SellersContentListing
									extractValidationErrors={extractValidationErrors}
									setOpenModal={setOpenModal}
									isLogin={isLogin}
								/>
							)}
						</Tab.Panels>
					</Tab.Group>
				</div>
				<KeepChecking
					open={false}
					history={props.history}
					path={`/discussions`}
				/>
				<div className="mt-28 -mb-36">
					<NeedHelp />
				</div>
			</div>
		</>
	);
}
