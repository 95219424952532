import React, { Component } from "react";
import { Buttonlink, IsLoader } from "components";
import { TextareaAutosize } from "@mui/material";
import { Dropdown } from "semantic-ui-react";
import "../../assets/css/semantic.css";
import { seo } from "../../helpers/seo";
import Alert from "@mui/material/Alert";
import client from "../../graphql/client";
import CreateContactus from "../../graphql/mutations/createContactus";
import { connect } from "react-redux";
import Auth from "../../Auth";
import { BsArrowDown, BsArrowRight } from "react-icons/bs";
import Subtract from "assets/images/Subtract.png";
import banner from "assets/images/contact.webp";
import { Switch } from "@headlessui/react";
import { scrollToContent } from "helpers/ScrollToContent";

const auth = new Auth();
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            description: "",
            reason: "",
            ticket_type: props.history.location.state
                ? props.history.location.state.header
                : null,
            loading: false,
            error: false,
            errors: "",
            success: false,
            enabled: false,
            options: [
                {
                    key: "1",
                    text: "Please Select the your ticket type ",
                    value: "Please Select the your ticket type ",
                },
                {
                    key: "2",
                    text: "Want to share a perspective for showcase",
                    value: "Want to share a perspective for showcase",
                },
                {
                    key: "3",
                    text: "Want to showcase an expert",
                    value: "Want to showcase an expert",
                },
                {
                    key: "4",
                    text: "Want to post a job",
                    value: "Want to post a job",
                },
                {
                    key: "5",
                    text: "Want to advertise on Procurement League",
                    value: "Want to advertise on Procurement League",
                },
                {
                    key: "6",
                    text: "Want to report an issue",
                    value: "Want to report an issue",
                },
                {
                    key: "7",
                    text: "Want to join the team",
                    value: "Want to join the team",
                },
                {
                    key: "8",
                    text: "Want to partner",
                    value: "Want to partner",
                },
                {
                    key: "9",
                    text: "want a consulting services",
                    value: "want a consulting services",
                },
                {
                    key: "10",
                    text: "want to subscribe for newsletter",
                    value: "want to subscribe for newsletter",
                },
                {
                    key: "11",
                    text: "want a training",
                    value: "want a training",
                },
                {
                    key: "12",
                    text: "want a best procurement contents",
                    value: "want a best procurement contents",
                },
                {
                    key: "13",
                    text: "want a conference pass at our special price",
                    value: "want a conference pass at our special price",
                },
                {
                    key: "14",
                    text: "want a development league mentorship sessions",
                    value: "want a development league mentorship sessions",
                },
                {
                    key: "15",
                    text: "want a digital procurement updates",
                    value: "want a digital procurement updates",
                },
                {
                    key: "16",
                    text: "want help in research",
                    value: "want help in research",
                },
                {
                    key: "17",
                    text: "want help in procurement outsourcing",
                    value: "want help in procurement outsourcing",
                },
                {
                    key: "18",
                    text: "want events updates",
                    value: "want events updates",
                },
                {
                    key: "19",
                    text: "wants help in media solutions",
                    value: "wants help in media solutions",
                },
                {
                    key: "20",
                    text: "want to join happy subscribers",
                    value: "want to join happy subscribers",
                },
            ],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (auth.isAuthenticated()) {
            const authUser = JSON.parse(localStorage.getItem('AUTH_USER'));
            if (authUser?.firstname && authUser?.lastname && authUser?.email) {
                this.setState({
                    name: `${authUser?.firstname} ${authUser?.lastname}`,
                    email: authUser?.email
                })
            }
        }
        seo({
            title: "Contact Us | Procurement League",
        });
    }

    simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 2000));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.error && this.state.errors.length > 0) {
            this.simulateNetworkRequest().then(() => {
                this.setState({
                    error: false,
                    errors: "",
                });
            });
        }
        if (this.state.success) {
            this.simulateNetworkRequest().then(() => {
                this.setState({
                    success: false,
                });
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { name, email, description, reason, ticket_type, error } = this.state;
        this.setState({
            ...this.state,
            loading: true,
            error: false,
            errors: "",
        });
        const errors = {};

        if (!name?.trim()) {
            errors.name = "Please provide your valid name";
        }

        if (!email?.trim()) {
            errors.email = "Please provide your valid email address";
        } else {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(email)) {
                errors.email = "Please enter a valid email address";
            }
        }

        if (!ticket_type?.trim() || ticket_type === "Please Select the your ticket type ") {
            errors.ticket_type = "Please Select valid topic";
        }

        if ((description || "")?.trim()?.length < 10) {
            errors.description = "Description must be atleast 10 characters";
        }

        if (!description?.trim()) {
            errors.description = "Please provide valid description";
        }

        if (!reason?.trim()) {
            errors.reason = "Please Type Your valid Subject";
        }

        console.log('errors :>> ', errors);

        if (Object.keys(errors).length > 0) {
            this.setState({
                loading: false,
                error: true,
                errors: Object.values(errors).join(", "),
            });
            return;
        }

        this.setState({
            ...this.state,
            error: false,
            errors: "",
        });


        if (!error) {
            this.setState({ loading: true });
            client
                .mutate({
                    mutation: CreateContactus,
                    variables: {
                        name,
                        email,
                        description,
                        reason,
                        ticket_type,
                    },
                })
                .then((response) => {
                    if (response.data.create_contactus.id !== "") {
                        this.setState({
                            name: "",
                            email: "",
                            description: "",
                            reason: "",
                            ticket_type: null,
                            loading: false,
                            success: true,
                            errors: "Submited",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        loading: false,
                        error: true,
                        errors: "Oops something went wrong",
                    });
                });
        }
    };
    handleInputChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    handleInputTopicsChange = (event, data) => {
        this.setState({
            ...this.state,
            ticket_type: data.value,
        });
    };

    hanldeSwithChange = () => {
        this.setState({ enabled: !this.state.enabled });
    };

    render() {
        const {
            options,
            name,
            email,
            description,
            reason,
            ticket_type,
            error,
            errors,
            success,
            enabled,
        } = this.state;
        return (
            <>
                <div className="bg--lightBlue pb-28 -mt-[50px]">
                    <div className="lg:bg-transparent md:bg-transparent bg-black">
                        <div className="lg:hidden md:hidden relative">
                            <img src={banner} alt="banner" className="object-cover w-full h-full object-center" />
                        </div>
                        <div className="lg:h-[90vh] md:h-[100vh] ContactBanner relative">
                            <div className="flex w-full max-w-2xl z-10 relative flex-col gap-5 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
                                <p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
                                    Contact Us
                                </p>
                                <p className="white Poppins font-normal">
                                    Elevate Your Brand as an Industry Leader,
                                    Share Company Profiles, and Host Webinars
                                </p>
                                <p className="white Poppins font-normal">
                                    Platform where seller meet buyers Discover
                                    how your company can benefit from these
                                    exclusive services:
                                </p>
                                <div className="h-px bg-white mt-3" />
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-4 items-center">
                                    <button onClick={() =>
                                        scrollToContent('contentSection', 1000)
                                    } className="group px-4 hover:bg--black border-2 border-transparent hover:border-white transition-all duration-1000 ease-out h-12 relative !font-normal Poppins rounded-full overflow-hidden bg--primary">
                                        <div className="absolute rounded-full inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></div>
                                        <span className="relative text-white flex Poppins items-center justify-between group-hover:text-white">
                                            Let's Chat <BsArrowDown size={23} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
                                <img
                                    src={Subtract}
                                    alt="Subtract"
                                    className="object-cover w-full left-1/2 -translate-x-1/2 relative"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pt-80 container mx-auto lg:relative">
                        <div className="absolute lg:block hidden left-0 top-0">
                            <svg
                                width="39"
                                height="39"
                                viewBox="0 0 39 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="19.5"
                                    cy="19.5"
                                    r="19.5"
                                    fill="#B5E2FF"
                                />
                            </svg>
                        </div>
                        <div className="absolute lg:block hidden right-0 -top-10">
                            <svg
                                width="108"
                                height="108"
                                viewBox="0 0 108 108"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_144_559)">
                                    <path
                                        d="M77.9776 32.1556L58.3588 41.1924L49.322 21.5737C47.0628 16.669 41.6898 14.6849 36.7852 16.9441C31.8805 19.2033 29.8964 24.5762 32.1556 29.4809L41.1924 49.0997L21.5737 58.1365C16.669 60.3957 14.6849 65.7687 16.9441 70.6733C19.2033 75.578 24.5762 77.5621 29.4809 75.3029L49.0997 66.2661L58.1365 85.8848C60.3957 90.7895 65.7686 92.7736 70.6733 90.5144C75.578 88.2552 77.5621 82.8823 75.3029 77.9776L66.2661 58.3588L85.8848 49.322C90.7895 47.0628 92.7736 41.6899 90.5144 36.7852C88.2552 31.8805 82.8823 29.8964 77.9776 32.1556Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_144_559">
                                        <rect
                                            width="81"
                                            height="81"
                                            fill="white"
                                            transform="translate(0 33.8882) rotate(-24.7319)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="container -mt-80 py-10 rounded-3xl bg-white shadow-[0px_4px_40px_0px_#0000001A]" id="contentSection">
                    <div className="relative mb-10">
                        <h1 className="text-[50px] leading-10 font-light text-center Poppins">
                            Let's Chat
                        </h1>
                    </div>
                    <div className="lg:px-20 md:px-16 sm:px-10 px-5 relative z-50">
                        <div className="mb-3 grid gap-x-10 gap-y-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
                            <div className="space-y-2 lg:col-span-1 md:col-span-1 sm:col-span-1 col-span-2">
                                <p className="text-[18px] text-[#3B455A] Poppins font-normal uppercase">
                                    Full name*
                                </p>
                                <div className="h-14 rounded-full important:bg--lightGray">
                                    <input
                                        type="text"
                                        placeholder="Your Name"
                                        maxLength={50}
                                        className="w-full h-14 px-5 bg-transparent Poppins border-0 text-lg shadow-none outline-0"
                                        onChange={this.handleInputChange}
                                        value={name || ""}
                                        name="name"
                                    />
                                </div>
                            </div>
                            <div className="space-y-2 lg:col-span-1 md:col-span-1 sm:col-span-1 col-span-2">
                                <p className="text-[18px] text-[#3B455A] Poppins font-normal uppercase">
                                    Subject
                                </p>
                                <div className="h-14 rounded-full important:bg--lightGray">
                                    <input
                                        type="text"
                                        placeholder="Your Subject"
                                        maxLength={50}
                                        className="w-full h-14 px-5 bg-transparent Poppins border-0 text-lg shadow-none outline-0"
                                        onChange={this.handleInputChange}
                                        value={reason}
                                        name="reason"
                                    />
                                </div>
                            </div>
                            <div className="space-y-2 lg:col-span-1 md:col-span-1 sm:col-span-1 col-span-2">
                                <p className="text-[18px] text-[#3B455A] Poppins font-normal uppercase">
                                    Email*
                                </p>
                                <div className="h-14 rounded-full important:bg--lightGray">
                                    <input
                                        type="email"
                                        placeholder="Your Email"
                                        maxLength={50}
                                        aria-describedby="inputGroupPrepend"
                                        className="w-full h-14 px-5 bg-transparent Poppins border-0 text-lg shadow-none outline-0"
                                        onChange={this.handleInputChange}
                                        value={email}
                                        name="email"
                                    />
                                </div>
                            </div>
                            <div className="space-y-2 lg:col-span-1 md:col-span-1 sm:col-span-1 col-span-2">
                                <p className="text-[18px] text-[#3B455A] Poppins font-normal uppercase">
                                    Topic*
                                </p>
                                <div className="rounded-full important:bg--lightGray">
                                    <Dropdown
                                        className="w-full Poppins !text-lg h-full important:border-0 gray !rounded-full important:bg--lightGray"
                                        placeholder="Topic"
                                        fluid
                                        selection
                                        options={options}
                                        onChange={this.handleInputTopicsChange}
                                        value={ticket_type}
                                        name="ticket_type"
                                    />
                                </div>
                            </div>
                            <div className="space-y-2 col-span-2">
                                <p className="text-[18px] text-[#3B455A] Poppins font-normal uppercase">
                                    Message*
                                </p>
                                <div className="w-full">
                                    <TextareaAutosize
                                        minRows={6}
                                        maxLength={300}
                                        minLength={10}
                                        placeholder="Please take a moment to let us know why you are reaching out by selecting an option from the above. Please describe with as much detail as possible in this section."
                                        onChange={this.handleInputChange}
                                        className="w-full Poppins text-lg h-full p-5 border-0 rounded-lg shadow-none resize-none important:bg--lightGray outline-0"
                                        value={description}
                                        name="description"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <Switch
                                checked={this.state.enabled}
                                onChange={() => this.hanldeSwithChange()}
                                className={`important:bg--lightGray flex-shrink-0 relative flex h-10 w-20 items-center rounded-full`}
                            >
                                <span className="sr-only">
                                    Enable notifications
                                </span>
                                <span
                                    className={`${enabled
                                        ? "translate-x-11 bg--primary"
                                        : "translate-x-1 bg-white"
                                        } inline-block h-8 w-8 transform  rounded-full transition-all duration-500`}
                                />
                            </Switch>
                            <span className="Poppins darkGray text-lg font-normal">
                                I would like to subscribe to the newsletter.{" "}
                            </span>
                        </div>
                        {error ? <div style={{ height: 10 }} /> : false}
                        {error ? (
                            <Alert severity="error">{errors}</Alert>
                        ) : null}
                        {success ? (
                            <>
                                <div style={{ height: 10 }} />
                                <Alert severity="success">
                                    Form is Submitted Successfully
                                </Alert>
                            </>
                        ) : null}
                        {
                            this.state.loading ?
                                <div className="flex items-center justify-center lg:w-1/5 w-full px-4 mt-5 border-0 shadow-none white hover:color-white Regular bg-[#3B455A] hover:opacity-70 h-11 rounded-full">
                                    <IsLoader className="w-20" white={true} />
                                </div>
                                :
                                <Buttonlink
                                    className="flex items-center justify-between lg:w-1/5 w-full px-4 mt-5 border-0 shadow-none white hover:color-white Regular bg-[#3B455A] hover:opacity-70 h-11 rounded-full"
                                    Text={"Send Message"}
                                    onClick={this.handleSubmit}
                                    Lasticon={<BsArrowRight size={20} />}
                                />
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(ContactUs);
