import { IsLoader, Buttonlink } from "components";
import React, { useEffect, useState } from "react";
import paymentImage from "assets/images/pricing/paymentImage.png"
import visaImage from "assets/images/pricing/visaImage.png"
import primaryBox from "assets/images/pricing/primaryBox.png"
import primaryCross from "assets/images/pricing/primaryCross.png"
import primaryPlus from "assets/images/pricing/primaryPlus.png"
import coursePaymentMutation from "../../graphql/mutations/coursePaymentMutation";
import ADD_FREE_ACCESS from "../../graphql/mutations/addFreeAccess";
import PAY_FROM_GOOGLE_PAY from "../../graphql/mutations/payFromGooglePay";
import client from "../../graphql/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import verifyCouponMutation from "graphql/mutations/verifyCouponMutation";
import GooglePayButton from "@google-pay/button-react";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import {
  GOOGLE_PAY_MERCHANT_ID,
  GOOGLE_PAY_MERCHANT_NAME,
  PAYEEZY_PAY_PAYMENT_GATEWAY_NAME,
  PAYMENT_GATEWAY_ENVIRONMENT,
  PAYMENT_GATEWAY_MERCHANT_ID,
} from "../../config/constants";
import { FcGoogle } from "react-icons/fc";
import Auth from "../../Auth";
import { BsArrowRight } from "react-icons/bs";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
const auth = new Auth();

const PaymentPage = (props) => {

  const [details, setDetails] = useState({
    couponNumber: props.check ? props.item.code : "",
    couponMessage: "",
    couponErrorStatus: true,
    course_id: null,
    selectedCard: null,
    plan: {},
    loading: false,
    activeCapsule: true,
    rmoveCapsule: true,
    terms_conditions: false,
    redirect: null,
    isReadyToPay: false,
    discount: 0,
    amount: "",
    disabled: false,
    disabledPaymentButton: true,
    email: "",
    address: "",
    applyLoading: ""
  });
  const isLogin = auth?.isAuthenticated();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props?.check && props?.item?.code) {
      applyCoupon();
    }
  }, [])

  function applyCoupon() {
    const { couponNumber } = details;
    const id = parseInt(props.item.id);

    if (couponNumber === "") {
      setDetails({
        ...details,
        couponMessage: "Please enter a valid coupon number",
        couponErrorStatus: true,
      });
    } else {
      setDetails({...details, applyLoading: true});
      client
        .mutate({
          mutation: verifyCouponMutation,
          variables: {
            package_id: id,
            coupon_code: couponNumber,
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.coupen_check_web.error) {
            setDetails({
              ...details,
              applyLoading: false,
              couponMessage: res.data.coupen_check_web.message,
              couponErrorStatus: res.data.coupen_check_web.error,
              amount: props.item?.amount,
            });
          } else {
            setDetails({
              ...details,
              applyLoading: false,
              couponMessage: res.data.coupen_check_web.message,
              couponErrorStatus: res.data.coupen_check_web.error,
              discount: res.data.coupen_check_web.discount,
              amount: res.data.coupen_check_web.amount,
            });
          }
        })
        .catch((err) => {
          setDetails({...details, applyLoading: false});
          console.log(err);
        });
    }
  };

  async function handleSubmit(pac_id) {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: details?.cardName,
        email: details?.email,
      },
    });

    if (error) {
      toast.error(error?.message, {
        position: "bottom-right",
        autoClose: 2000,
      });
    } else {
      const variables = {
        card_holder_name: details?.cardName,
        package_id: parseInt(pac_id),
        course_id: parseInt(details?.course_id || props?.item?.id),
        coupon_code: details?.coupon_code,
        email: details?.email,
        payment_method_id: paymentMethod?.id
      };

      try {
        setDetails({ ...details, loading: true });
        const results = await client.mutate({
          mutation: coursePaymentMutation,
          variables
        });

        setDetails({ ...details, loading: false });

        if (results.data.add_client_card.error) {
          notify(results.data.add_client_card.message, "error");
        } else {
          notify("Payment Successful!", "success");
          const redirectUrl = type === "item_based" ? "/products" : "/classes";
          window.location.href = !isLogin ? redirectUrl: '/payment-history';
        }
      } catch (error) {
        setDetails({ ...details, loading: false });
        notify(`Payment Error: ${error}`, "error");
      }
    }
  }

  const submitPayments = (id) => {
    let item = props.item;
    let type = props.type;
    let price = item.price;

    if (!auth.isAuthenticated() && !details?.email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
      return notify("Your Email Address is invalid!");
    }

    let plan = { course_id: item.id, type: type, price: price };

    type === "item_based"
      ? setDetails({ ...details, plan: plan }, handleSubmit(id))
      : handleSubmit(id);
  };

  const onReadyToPayChange = (data) => {
    setDetails({ ...details, isReadyToPay: true });
    if (data?.isReadyToPay && details?.isReadyToPay) {
      notify(`Google Pay is Ready`, "info");
    }
  };

  const onSuccessNotification = (data) => {
    if (data.paymentMethodData.tokenizationData.type) {
      notify("Purchase Successful", "success");
      setDetails({ ...details, loading: true });
      client
        .mutate({
          mutation: PAY_FROM_GOOGLE_PAY,
          variables: {
            card_type: data.paymentMethodData.tokenizationData.type,
            package_id:
              props.type === "subscription"
                ? props.item.id
                : props.item.price,
            course_id:
              props.type === "item_based" ? props.item.id : null,
            // coupon_code,
          },
        })
        .then((results) => {
          setDetails({ ...details, loading: false });
          if (details?.type === "item_based") {
            window.location.href = "/products";
          } else {
            window.location.href = "/classes";
          }
        })
        .catch((error) => {
          setDetails({ ...details, loading: false });
          notify(`Payment Error...1:, ${error}`, "error");
        });
    } else {
      setDetails({ ...details, loading: false });
      notify("Payment Failed", "error");
    }
  };

  function notify(message = "", type = "error") {
    if (type === "error") {
      return toast.error(message, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    if (type === "success") {
      toast.success(message, { position: "bottom-right" });
    }
    if (type === "info") {
      toast.info(message, { position: "bottom-right" });
    }
  };

  function handleValidation() {
    const { email, terms_conditions, loading } = details;

    const isEmailValid = auth.isAuthenticated() || !!email;
    const isTerms_conditions = terms_conditions;
    const isLoading = !loading;

    return isEmailValid && isTerms_conditions && isLoading;
  }


  // activeCapsule = () => {
  //   setDetails({ ...details, activeCapsule: !this.state.activeCapsule });
  // };

  const freeAccess = () => {
    if (!auth.isAuthenticated() && details?.email === "") {
      setTimeout(() => {
        return notify(`Email address is required`, "error");
      }, 2000);
    } else {
      setDetails({...details, loading: true});
      client
        .mutate({
          mutation: ADD_FREE_ACCESS,
          variables: {
            orderIdOrPlainId: Number(props?.item?.id),
            order_type: props?.item?.data_type,
            coupon_code: details?.couponNumber,
            discount: details?.discount?.toString(),
            amount: details?.amount,
            email: details?.email,
          },
        })
        .then((results) => {
          if (results?.data?.addFreeAccess?.error) {
            setDetails({ ...details, email: "" });
            notify(results?.data?.addFreeAccess?.message, "error");
          } else {
            notify(results?.data?.addFreeAccess?.message, "success");
            const redirectUrl = type === "item_based" ? "/products" : "/classes";
            window.location.href = redirectUrl;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setDetails({...details, loading: false});
        });
    }
  };

  const { type, item, location } = props;
  return (
    <>
      <div className="relative bg--lightBlue px-3 -mt-[50px] -mb-[75px] flex justify-center items-center">
        <img
          src={primaryBox}
          alt=""
          className="absolute w-[60px] h-[60px] right-10 top-10 lg:block hidden"
        />
        <img
          src={primaryCross}
          alt=""
          className="absolute w-[212px] h-[212px] left-28 bottom-52 lg:block hidden"
        />
        <img
          src={primaryPlus}
          alt=""
          className="absolute w-[106px] h-[106px] left-[800px] bottom-[310px] lg:block hidden"
        />
        <img
          src={primaryCross}
          alt=""
          className="absolute w-[150px] h-[150px] left-[950px] bottom-72 lg:block hidden"
        />
        <div className="bg-white p-2 shadow-[0px_4px_40px_0px_#0000001A] flex flex-col items-center lg:w-[1250px] lg:h-[1200px] w-[600px] lg:mb-[450px] mb-[100px] mt-28 rounded-3xl py-8">
          <div className="Poppins text-[50px] font-[300]">Payment</div>
          <div
            className="relative lg:gap-10 lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-12 text-center flex justify-center items-center"
          >
            <div className="grid gap-4 lg:grid-cols-6 mx-auto w-full max-w-[720px]">
              <div className="lg:col-start-1 lg:col-span-6 w-full">
                {/* {item.staticcontext ? null : (
                <Buttonlink
                  Text="back"
                  Icon={<IoIosArrowBack />}
                  onClick={location.goBack}
                  className="flex items-center justify-center h-8 mb-3 border-0 shadow-none bg--lightGray focus:bg--lightGray hover:bg--lightGray rounded--full Regular darkGray w-28 hover:darkGray hover:opacity-80"
                />
              )} */}
                <div className="mb-5 w-full">
                  <div className="grid grid-cols-4 justify-between gap-x-1">
                    <div className="flex-grow col-start-1 col-span-3">
                      <input
                        type="text"
                        placeholder="Coupon Code"
                        disabled={props.check}
                        className="w-full px-3 bg-[#F2F2F2] rounded-[30px] border-0 h-11 Regular"
                        value={details?.couponNumber || ''}
                        onChange={(e) =>
                          setDetails({ ...details, couponNumber: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-start-4 col-span-1">
                      <Buttonlink
                        disabled={props?.check}
                        Text={details?.applyLoading ? <IsLoader className="w-20" white={true} /> :"Apply"}
                        Lasticon={!details?.applyLoading && <BsArrowRight size={24} />}
                        className={`${props?.check
                          ? "bg--gray hover:bg--gray focus:bg--gray"
                          : "bg--primary hover:bg--primary focus:bg--primary"
                          } flex items-center justify-center gap-4 w-full px-4 border-0 rounded-[50px] Regular white fs-13  hover:color-white hover:opacity-80 h-11`}
                        onClick={(e) => applyCoupon()}
                      />
                    </div>
                  </div>

                  {/*{!couponErrorStatus && couponMessage &&  (*/}
                  <p className="pt-2 danger fs-12">{details?.couponMessage}</p>
                  {/*)}*/}
                </div>

                <div className="p-5 mb-5 shadow--sm rounded-[30px]">
                  <p className="text-[#B2B2B2] font-[400] text-start Poppins">
                    {type === "subscription" ? "Subscription Plan:" : "Product:"}
                  </p>
                  <p className="Poppins text-start font-[700] text-[#B2B2B2]">
                    {item.name}{""}
                    {type === "subscription" && item.name === "ProcureClass-Pro"
                      ? "/Monthly"
                      : type === "item_based"
                        ? null
                        : "/Yearly"}
                  </p>
                  <div className="flex items-center justify-between mt-2">
                    <p className="Poppins text-start font-[700] text-[#B2B2B2]">Amount</p>
                    <div className="flex items-center mr-8">
                      <p className="Poppins text-[30px] font-[600]">{item.price}.00 USD</p>
                    </div>
                  </div>
                  {!details?.couponErrorStatus && details?.amount !== "" ? (
                    <>
                      <div className="flex items-center justify-between mt-2">
                        <p className="fs-14">Discount</p>
                        <div className="flex items-center">
                          <p className="Medium">{details?.discount}%</p>
                          <p className="ml-1">OFF</p>
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <p className="fs-14">Total Payable</p>
                        <div className="flex items-center">
                          <p className="Medium">{details?.amount}.00</p>
                          <p className="ml-1"> USD</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
                {details?.couponErrorStatus === false && details?.amount <= 1 ? (
                  <>
                    {!auth.isAuthenticated() && (
                      <div className="mb-3">
                        <p className="mb-1 fs-14">Email Address</p>
                        <input
                          type="email"
                          placeholder="Email"
                          maxLength={32}
                          className="w-full px-3 bg-[#F2F2F2] border-0 rounded-lg h-11 Regular"
                          value={details?.email}
                          onChange={(e) =>
                            setDetails({ ...details, email: e.target.value })
                          }
                        />
                      </div>
                    )}
                    <div className="flex justify-start items-center mb-8">
                        <Switch
                          checked={details?.terms_conditions}
                          onChange={() => {
                            setDetails({
                              ...details,
                              terms_conditions: !details?.terms_conditions,
                            });
                          }}
                          className={`bg--lightGray relative inline-flex h-10 w-20 items-center rounded-full`}
                        >
                          <span className="sr-only">
                            Enable notifications
                          </span>
                          <span
                            className={`${details?.terms_conditions
                              ? "lg:translate-x-11 translate-x-11 bg--primary"
                              : "translate-x-1 bg-white"
                              } inline-block h-8 w-8 transform rounded-full transition-all duration-500`}
                          />
                        </Switch>
                        <div className="ml-10 Poppins text-[#B2B2B2] text-[18px] font-[400]">Agree to our{' '}
                          <Link to='/term-of-services' className="primary underline">Terms & condition</Link>
                        </div>
                      </div>
                    <Buttonlink
                      Text={details?.loading ? 
                        <IsLoader className="h-full" white={true} />: 
                        `Proceed ${type === "item_based" ? "Product" : "Subscription"
                          } Free Access`
                      }
                      className={
                        details?.terms_conditions
                          ? "Regular white bg--primary hover:color-white hover:bg--primary focus:bg--primary hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-lg"
                          : "Regular white important:bg--gray hover:color-white hover:bg--grey focus:bg--grey hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-lg"
                      }
                      onClick={() =>
                        !details?.terms_conditions
                          ? setDetails({ ...details, disabled: true })
                          : freeAccess()
                      }
                      disabled={!details?.terms_conditions}
                    />
                  </>
                ) : (
                  <>
                    {!details?.terms_conditions ? (
                      <button onClick={() => setDetails({ ...details, disabled: true })} className={
                        "Poppins uppercase white px-5 bg-[#3B455A] hover:color-white gap-2.5 text-[18px] font-[700] hover:bg--black focus:bg--black hover:opacity-80 h-[50px] w-full border-0 flex items-center justify-center rounded-full"
                      }>
                        <div className="flex flex-grow justify-center items-center gap-2">
                          <FcGoogle /> Pay
                        </div>
                        <BsArrowRight size={22} />
                      </button>
                    ) : details?.cardName?.length > 0 ? null : (
                      <GooglePayButton
                        environment={PAYMENT_GATEWAY_ENVIRONMENT}
                        existingPaymentMethodRequired={false}
                        paymentRequest={{
                          apiVersion: 2,
                          apiVersionMinor: 0,
                          emailRequired: true,
                          allowedPaymentMethods: [
                            {
                              type: "CARD",
                              parameters: {
                                allowedAuthMethods: [
                                  "PAN_ONLY",
                                  "CRYPTOGRAM_3DS",
                                ],
                                allowedCardNetworks: [
                                  "AMEX",
                                  "DISCOVER",
                                  "INTERAC",
                                  "JCB",
                                  "MASTERCARD",
                                  "VISA",
                                ],
                              },
                              tokenizationSpecification: {
                                type: "PAYMENT_GATEWAY",
                                parameters: {
                                  gateway: PAYEEZY_PAY_PAYMENT_GATEWAY_NAME,
                                  gatewayMerchantId: PAYMENT_GATEWAY_MERCHANT_ID,
                                },
                              },
                            },
                          ],
                          merchantInfo: {
                            merchantId: GOOGLE_PAY_MERCHANT_ID,
                            merchantName: GOOGLE_PAY_MERCHANT_NAME,
                          },
                          transactionInfo: {
                            totalPriceStatus: "FINAL",
                            totalPriceLabel: "Total",
                            totalPrice:
                              !details?.couponErrorStatus && details?.amount !== ""
                                ? details?.amount.toString()
                                : item?.price.toString(),
                            currencyCode: "USD",
                            countryCode: "US",
                            transactionNote: "All purchases are final",
                            checkoutOption: "DEFAULT",
                          },
                          shippingAddressRequired: item.is_shippable === true,
                          callbackIntents: ["PAYMENT_AUTHORIZATION"],
                        }}
                        // onLoadPaymentData={paymentRequest => {
                        //   console.log("paymentRequest",paymentRequest)
                        //   // this.onSuccessNotification(paymentRequest)
                        // }}
                        onPaymentAuthorized={(paymentData) => {
                          // console.log("paymentData",paymentData)
                          onSuccessNotification(paymentData);
                        }}
                        onReadyToPayChange={(result) => {
                          onReadyToPayChange(result);
                        }}
                        buttonColor="black"
                        buttonSizeMode={"fill"}
                        className="w-full w-full-all"
                      />
                    )}
                    <div className="flex flex-row items-center my-4">
                      <div className="flex-grow rounded-full divider" />
                      <p className="mx-4 gray">Or</p>
                      <div className="flex-grow rounded-full divider" />
                    </div>
                    <div className="p-3">
                      {!auth.isAuthenticated() && (
                        <div className="mb-3">
                          <p className="text-start text-[18px] font-[400] Poppins uppercase mb-3 text-[#3B455A]">Email*</p>
                          <input
                            type="email"
                            placeholder="email@gmail.com"
                            maxLength={32}
                            className="w-full px-3 bg-[#F2F2F2] border-0 rounded-full h-11 mb-5  Regular"
                            value={details?.email}
                            onChange={(e) =>
                              setDetails({ ...details, email: e.target.value })
                            }
                          />
                        </div>
                      )}
                      <div className="mb-3">
                        <p className="text-start text-[18px] font-[400] Poppins uppercase mb-3 text-[#3B455A]">Cardholder name*</p>
                        <input
                          type="text"
                          placeholder="Name Surname"
                          maxLength={32}
                          className="w-full px-3 bg-[#F2F2F2] border-0 rounded-full h-11 mb-5  Regular"
                          value={details?.cardName}
                          onChange={(e) =>
                            setDetails({ ...details, cardName: e.target.value })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <p className="text-start text-[18px] font-[400] Poppins uppercase mb-3 text-[#3B455A]">
                          Card Details
                        </p>
                        <CardElement className="w-full px-3 bg-[#F2F2F2] border-0 rounded-full py-4 Regular" autoComplete="off" />
                      </div>
                      <div className="flex justify-start items-center mb-8">
                        <Switch
                          checked={details?.terms_conditions}
                          onChange={() => {
                            setDetails({
                              ...details,
                              terms_conditions: !details?.terms_conditions,
                            });
                          }}
                          className={`bg--lightGray relative inline-flex h-10 w-20 items-center rounded-full`}
                        >
                          <span className="sr-only">
                            Enable notifications
                          </span>
                          <span
                            className={`${details?.terms_conditions
                              ? "lg:translate-x-11 translate-x-11 bg--primary"
                              : "translate-x-1 bg-white"
                              } inline-block h-8 w-8 transform rounded-full transition-all duration-500`}
                          />
                        </Switch>
                        <div className="ml-10 Poppins text-[#B2B2B2] text-[18px] font-[400]">Agree to our{' '}
                          <Link to='/term-of-services' className="primary underline">Terms & condition</Link>
                        </div>
                      </div>
                      <Buttonlink
                        Text={
                          details?.loading ? (
                            <IsLoader className="h-full" white={true} />
                          ) : (
                            `Proceed to pay ${details?.couponErrorStatus
                              ? item.price
                              : details?.amount
                            }.00 USD`
                          )
                        }
                        className={
                          handleValidation()
                            ? "Poppins text-[18px] font-[700] uppercase white bg-[#1A424E] hover:color-white hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-full"
                            : "Poppins text-[18px] font-[700] uppercase white  bg-[#3B455A] hover:color-white hover:bg--grey focus:bg--grey hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-full"
                        }
                        onClick={() => {
                          submitPayments(
                            type === "item_based" ? item.price : item.id
                          );
                          //handleSubmit(type === "item_based" ? item.price : item.id)
                        }}
                        disabled={!handleValidation() || !stripe}
                      />
                      <div className="flex items-start mt-8 gap-2">
                        <img
                          src={visaImage}
                          alt="visa"
                          className="object-contain h-[65px]"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="font-bold Poppins text-[18px] mt-4 lg:block hidden">
              <img
                src={paymentImage}
                alt={"payment image"}
                className="w-[300px] h-[731px] object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPage;
