import { useEffect, useRef, useState } from "react";
import Auth from "../../Auth";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Switch } from "@headlessui/react";
import Buttonlink from "components/allcomponents/buttonlink";
import IsLoader from "components/allcomponents/IsLoader";
import visaImage from "assets/images/pricing/visaImage.png";
import { Link } from "react-router-dom";
import Emitter from "services/emitter-service";
import client from "graphql/client";
import CHARGE_PAYMENT from "../../graphql/mutations/chargePayment";
import { toast } from "react-toastify";
import MyAuthUserAction from "store/actions/authUserAction";
import { DialogBox } from "components/Dialog";
const auth = new Auth();

export const PaymentModal = () => {
	const [details, setDetails] = useState({});
	const [loading, setLoading] = useState(false);
	const stripe = useStripe();
	const elements = useElements();
	const dialogRef = useRef(null);

	const OpenModal = (data) => {
		setDetails((prevDetails) => ({ ...prevDetails, ...data }));
		dialogRef.current.open();
	};
	

	const handlePaymentSubmit = async () => {
		if (!stripe || !elements) {
			return;
		}
		setLoading(true);
		const cardElement = elements.getElement(CardElement);

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
			billing_details: {
				name: details?.cardName,
				email: details?.email,
			},
		});

		if (error) {
			setLoading(false);
			toast.error(error?.message, {
				position: "bottom-right",
				autoClose: 2000,
			});
		} else {
			try {
				let tempDetails = { ...details, payment_method_id: paymentMethod?.id };
				const json = await client.mutate({
					mutation: CHARGE_PAYMENT,
					variables: tempDetails,
				});
				if (json?.data?.chargePayment?.success) {
					dialogRef.current.close();
					toast.success(
						json?.data?.chargePayment?.message ||
							"We have recieved your payment!"
					);
					Emitter.emit("refetchTransactions");
					await MyAuthUserAction.getAuthUserInformation();
				} else {
					toast.error(json?.data?.chargePayment?.message || "Error!");
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
	};

	function handleValidation() {
		const { email, terms_conditions, loading } = details;

		const isEmailValid = auth.isAuthenticated() || !!email;
		const isTerms_conditions = terms_conditions;
		const isLoading = !loading;

		return isEmailValid && isTerms_conditions && isLoading;
	}

	useEffect(() => {
		Emitter.on("paymentModal", OpenModal);
		return () => {
			Emitter.off("paymentModal", OpenModal);
		};
	}, []);

	return (
		<DialogBox ref={dialogRef} size={"md"}>
			<h1 className="text-center text-3xl font-light w-full ">
				Pay Via Stripe
			</h1>
			<div className="p-3">
				{!auth.isAuthenticated() && (
					<div className="mb-3">
						<p className="text-start text-[18px] font-[400] Poppins uppercase mb-3 text-[#3B455A]">
							Email*
						</p>
						<input
							type="email"
							placeholder="email@gmail.com"
							maxLength={32}
							className="w-full px-3 bg-[#F2F2F2] border-0 rounded-full h-11 mb-5  Regular"
							value={details?.email || ""}
							onChange={(e) =>
								setDetails({ ...details, email: e.target.value })
							}
						/>
					</div>
				)}
				<div className="mb-3">
					<p className="text-start text-[18px] font-[400] Poppins uppercase mb-3 text-[#3B455A]">
						Cardholder name*
					</p>
					<input
						type="text"
						placeholder="Name Surname"
						maxLength={32}
						className="w-full px-3 bg-[#F2F2F2] border-0 rounded-full h-11 mb-5  Regular"
						value={details?.name || ""}
						onChange={(e) => setDetails({ ...details, name: e.target.value })}
					/>
				</div>
				<div className="mb-3">
					<p className="text-start text-[18px] font-[400] Poppins uppercase mb-3 text-[#3B455A]">
						Card Details
					</p>
					<CardElement
						className="w-full px-3 bg-[#F2F2F2] border-0 rounded-full py-4 Regular"
						autoComplete="off"
					/>
				</div>
				<div className="flex justify-start items-center mb-8">
					<Switch
						checked={details?.terms_conditions}
						onChange={() => {
							setDetails({
								...details,
								terms_conditions: !details?.terms_conditions,
							});
						}}
						className={`bg--lightGray relative inline-flex h-10 w-20 items-center rounded-full`}
					>
						<span className="sr-only">Enable notifications</span>
						<span
							className={`${
								details?.terms_conditions
									? "lg:translate-x-11 translate-x-10 bg--primary"
									: "translate-x-1 bg-white"
							} inline-block h-8 w-8 transform rounded-full transition-all duration-500`}
						/>
					</Switch>
					<div className="ml-10 Poppins text-[#B2B2B2] text-[18px] font-[400]">
						Agree to our{" "}
						<Link to="/term-of-services" className="primary underline">
							Terms & condition
						</Link>
					</div>
				</div>
				<Buttonlink
					Text={
						loading ? (
							<IsLoader className="h-full" white={true} />
						) : (
							`Proceed to pay ${details?.amount}.00 USD`
						)
					}
					onClick={() => handlePaymentSubmit()}
					disabled={!handleValidation() || !stripe}
					className={
						handleValidation()
							? "Poppins text-[18px] font-[700] uppercase white bg-[#1A424E] hover:color-white hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-full"
							: "Poppins text-[18px] font-[700] uppercase white  bg-[#3B455A] hover:color-white hover:bg--grey focus:bg--grey hover:opacity-80 h-11 w-full border-0 flex items-center justify-center rounded-full"
					}
				/>
				<div className="flex items-start mt-8 gap-2">
					<img src={visaImage} alt="visa" className="object-contain h-[65px]" />
				</div>
			</div>
		</DialogBox>
	);
};
