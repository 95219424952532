import React, { useEffect, useState } from "react";
import { Blogskeleton, IsLoader, Noconnection } from "components";
import { Query } from "@apollo/client/react/components";
import { GoSearch } from "react-icons/go";
import { debounce } from "lodash";
import { Dropdown } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import SELLERS_CONTENT from "../../../graphql/queries/sellerContents";
import All_Tags from "../../../graphql/queries/getAllTags";
import { Link } from "react-router-dom";
import convertToSlug from "helpers/convertToSlug";
import encryptfunction from "helpers/Encrypt";
import { FaEye } from "react-icons/fa";

export const SellersContentListing = ({
	extractValidationErrors,
	setOpenModal,
	isLogin,
}) => {
	const [variables, setVariables] = useState({ status: true, page: 1 });
	const [selected, setSelected] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleNameChange = debounce((value) => {
		setVariables({ ...variables, title: value });
	}, 1500);

	const { data } = useQuery(All_Tags);

	const options = (data?.alltags || [])?.map((t, index) => ({
		key: index,
		text: t?.tag_title,
		value: Number(t.id),
	}));

	const onLoadMore = async (data, fetchMore) => {
		setIsLoading(true);

		try {
			await fetchMore({
				variables: {
					...variables,
					page: data.sellerContents.paginatorInfo.currentPage + 1,
				},
				updateQuery: (previousResult, { fetchMoreResult }) => {
					const newContents = fetchMoreResult.sellerContents.data;
					const pageInfo = fetchMoreResult.sellerContents.paginatorInfo;
					return fetchMoreResult.sellerContents
						? {
								sellerContents: {
									__typename: previousResult?.sellerContents?.__typename,
									data: [
										...(previousResult?.sellerContents?.data || []),
										...newContents,
									],
									paginatorInfo: pageInfo,
								},
						  }
						: previousResult;
				},
			});
		} catch (error) {
			console.error("Error fetching more:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const selectCategories = (_, data) => {
		setSelected(data.value);
	};

	return (
		<>
			<div className="flex lg:flex-row flex-col gap-y-5 justify-between items-start gap-x-20">
				<div className="flex items-center px-2 bg-gray-100 w-full rounded-full md:px-5 h-14">
					<input
						placeholder="Start Typing to Search"
						className="flex-grow w-full pr-4 rounded-xl bg-gray-100 border-0 outline-none h-12 Regular"
						name="searchTitle"
						onChange={(e) => {
							const inputValue = e.target.value;
							handleNameChange.cancel();
							handleNameChange(inputValue);
						}}
					/>
					<GoSearch size={22} className="gray" />
				</div>
				<Dropdown
					className="flex-grow w-full pr-4 rounded-xl bg-gray-100 border-0 outline-none Regular"
					placeholder="Filter Based on Tags"
					fluid
					multiple
					search={true}
					selection
					value={selected}
					options={options}
					onChange={selectCategories}
				/>
			</div>
			<Query
				query={SELLERS_CONTENT}
				fetchPolicy={"cache-and-network"}
				variables={{ ...variables, tag_ids: selected }}
			>
				{({ loading, error, data, fetchMore, refetch }) => {
					useEffect(() => {
						window.scrollTo({
							top: 0,
							behavior: "smooth",
						});
					}, [loading]);

					if (loading)
						return (
							<div className="grid grid-cols-1 my-8 gap-16 md:grid-cols-2 sm:grid-cols-2">
								<Blogskeleton />
								<Blogskeleton />
							</div>
						);

					if (error) {
						extractValidationErrors(error);
						return (
							<div>
								<Noconnection />
							</div>
						);
					}
					if ((data?.sellerContents?.data || [])?.length !== 0) {
						return (
							<div className="grid grid-cols-1 mt-8 lg:grid-cols-2 w-full md:grid-cols-2 gap-16">
								{data?.sellerContents?.data?.map((content) => {
									return (
										<div
											className="block bg-white relative p-5 transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl"
											key={content?.title}
										>
											<div className="absolute bottom-5 right-5 flex text-sm text-gray-600 justify-center items-center gap-x-2">
												<FaEye /> {content?.view_count || 0}
											</div>
											<div className="absolute top-4 left-4">
												<div
													className={`text-sm ${
														content?.paid ? "text-green-500" : "primary"
													}`}
												>
													{content?.paid ? "Paid" : "Free"}
												</div>
											</div>
											{content?.paid && content?.tokens && (
												<div className="absolute top-4 right-4">
													<div
														className={`p-2 rounded-full text-sm text-white bg-green-500`}
													>
														{content?.tokens} Ȼ
													</div>
												</div>
											)}
											<div className="flex flex-col h-full justify-center items-center">
												<p className="text-center primary text-lg font-semibold">
													{content?.type}
												</p>

												<div className="px-5 py-5 space-y-4">
													<div className="space-y-2">
														<p className="text-base break-all line-clamp-2 text-center darkGray font-bold">
															{content?.title}
														</p>
													</div>
												</div>
												<div className="flex justify-center items-center flex-wrap gap-2 my-3">
													{content?.tags?.map((tag) => {
														return (
															<div className="px-2 py-0.5 bg-gray-200 text-sm rounded-full">
																{tag?.tag_title}
															</div>
														);
													})}
												</div>
												<div className="flex flex-grow justify-end items-end pt-3">
													<Link
														onClick={() => !isLogin && setOpenModal(true)}
														to={
															!isLogin
																? `/sellers`
																: `/seller/content/${convertToSlug(
																		content?.type
																  )}/${encryptfunction(content?.id)}`
														}
														className="text-center primary font-semibold text-base px-4 py-2 hover:shadow-md shadow-lg w-max mx-auto rounded-full"
													>
														View Content
													</Link>
												</div>
											</div>
										</div>
									);
								})}
								{data?.sellerContents?.paginatorInfo?.hasMorePages ? (
									<div className="flex justify-center items-center col-span-1 lg:col-span-2 md:col-span-2">
										{isLoading ? (
											<IsLoader className="w-32 h-32 mx-auto" />
										) : (
											<button
												onClick={() => onLoadMore(data, fetchMore)}
												className="py-2 px-3 bg--primary text-white rounded-lg hover:opacity-80"
											>
												Load more
											</button>
										)}
									</div>
								) : null}
							</div>
						);
					}
					return (
						<div className="text-center text-gray-500 text-3xl mt-10 w-full">
							No Content Found!
						</div>
					);
				}}
			</Query>
		</>
	);
};
