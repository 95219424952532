import axios from "axios";
// import { store } from "../store/index.ts";
import cookie from "js-cookie";
// import { logout } from "../util/logout.js";
// import { toastNotification } from "./notification-service.js";

export default class Api {
    _api = null;
    static requestInterceptor;

    static clearClientToken() {
        this._api.interceptors.request.eject(this.requestInterceptor);
    }

    static init = (url) => {
        const token = cookie.get('GRAPH_USER_AUTH_TOKEN');
        try {
            this._api = axios.create({
                baseURL: url,
                timeout: 20000,
                headers: {
                    Authorization: token ? `Bearer ${token}` : null
                }
            });

            this.clearClientToken();
            // Add request interceptor
            this._api.interceptors.request.use(
                config => {
                    // Add the token to the request headers
                    config.headers.Authorization = `Bearer ${token}`;
                    return config;
                },
                error => {
                    return Promise.reject(error);
                }
            );

            // Add response interceptor
            this._api.interceptors.response.use(
                response => {
                    // Any status code that lie within the range of 2xx cause this function to trigger
                    // Do something with response data
                    return response;
                },
                error => {
                    // Any status codes that falls outside the range of 2xx cause this function to trigger
                    // Do something with response error
                    return Promise.reject(error);
                }
            );
        } catch (error) {
            return error;
        }
    };


    static clearClientToken() {
        // Eject the interceptor to remove the token
        this._api.interceptors.request.eject(this.requestInterceptor);
    }

    static setClientToken = (token) => {

        // Clear any existing interceptor before setting a new token
        this.clearClientToken();

        this.requestInterceptor = this._api.interceptors.request.use(async (config) => {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    };

    static jobViewIncrement = async (params) => {
        try {
            const response = await this._api.post(`/admin/job-central/updateByKey`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static fetchResume = async (params) => {
        try {
            const response = await this._api.get(`job-central/fetch-resume`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static addResume = async (params) => {
        try {
            const response = await this._api.post(`upload-resume`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static submitJobCandidate = async (params) => {
        try {
            const response = await this._api.post(`job-central/job-apply`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static fetchJobs = async (params,pageNumber) => {
        try {
            const response = await this._api.post(`job-central/listing?page=${pageNumber}`, {...params, is_published: true});
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static fetchMyJobs = async (params) => {
        try {
            const response = await this._api.post(`saved-jobs`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    static fetchJobDetails = async (id) => {
        try {
            const response = await this._api.get(`/job-central/details/${id}`);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    static changeSaveStatus = async (params) => {
        try {
            const response = await this._api.post(`/save-job`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    static resumeParsing = async (params) => {
        try {
            const response = await this._api.post(`/job-central/parse_resume`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    static welcomeEmail = async (params) => {
        try {
            const response = await this._api.post(`/job-central/welcome-email`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    static sendEmail = async (params) => {
        try {
            const response = await this._api.post(`/send-email`, params);
            return response;
        } catch (error) {
            return error.response;
        }
    };

    static uploadFile = async (formdata) => {
        try {
            const token = cookie.get('GRAPH_USER_AUTH_TOKEN');
            const response = await this._api.post(`/s3upload`, 
            formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                    'Content-Type': 'multipart/form-data' // Ensure this header is set
                } 
            });
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static fetchChatProfiles = async () => {
        try {
            const response = await this._api.get(`/chats`)
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static fetchChatMessages = async (senderId,receiverId) => {
        try {
            const response = await this._api.get(`/chats/${senderId}/${receiverId}`)
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static sendChatMessages = async (formData) => {
        
        try {
            const response = await this._api.post("/chats/send",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static deleteChatMessages = async (id) => {
        try {
            const response = await this._api.delete(`/chats/delete/${id}`);
            return response;
        } catch (error) {
            return error.response;
        }
    };
    static startChatMessages = async (id) => {
        try {
            const response = await this._api.post(`/chats/start`,{id:id});
            return response;
        } catch (error) {
            return error.response;
        }
    };
}