import { Signinpopup } from "components";
import { Modal } from "components/Modal";
import { useState } from "react";
import { FirstScreen } from "./FirstScreen";

export const OptionsModal = ({ modalRef }) => {
	const [openModal, setOpenModal] = useState(false);
	const [screen, setScreen] = useState(1);

	return (
		<>
			<Modal ref={modalRef} size={screen === 1 ? "sm" : "md"}>
				<FirstScreen setScreen={setScreen} />
			</Modal>
			{openModal && (
				<Signinpopup
					open={openModal}
					closePopup={() => {
						setScreen(1);
						setOpenModal(false);
					}}
					protected={false}
				/>
			)}
		</>
	);
};
