import IsLoader from "components/allcomponents/IsLoader";
import Buttonlink from "components/allcomponents/buttonlink";
import { useState } from "react";

export const Form = ({ fields, handleCallback, errors, loading }) => {
	const [data, setData] = useState({});

	const handleSubmit = (e) => {
		e.preventDefault();
		handleCallback(data);
	};

	const handleChange = (e) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	return (
		<div className="w-full">
			<form onSubmit={handleSubmit} className="gap-y-4 w-full">
				<div className="flex flex-col justify-start items-start gap-y-4 w-full">
					{(fields || [])?.map((field) => {
						if (field?.type === "textarea") {
							return (
								<>
									<div className="space-y-2 w-full">
										<label htmlFor="purpose">
											{field?.label} {field?.required && "*"}
										</label>
										<textarea
											value={data?.[field?.name] || ""}
											name={field?.name}
											onChange={handleChange}
											rows={6}
											className="relative resize-none block w-full px-5 Poppins py-4 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-3xl bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
											required={field?.required}
											placeholder={field?.label}
										></textarea>
									</div>
									{errors?.[field?.name]?.[0] && (
										<div className="-mt-3 text-sm font-light text-red-600">
											{errors?.[field?.name]?.[0]}
										</div>
									)}
								</>
							);
						}
						return (
							<>
								<div className="space-y-2 w-full">
									<label htmlFor={field?.name}>
										{field?.label} {field?.required && "*"}
									</label>
									<input
										id={field?.name}
										value={data?.[field?.name] || ""}
										name={field?.name}
										onChange={handleChange}
										type={field?.type}
										className="relative block w-full h-12 px-5 Poppins py-2 text-gray-900 placeholder-[#b2b2b2] appearance-none rounded-full bg-[#F2F2F2] focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
										placeholder={field?.label}
										required={field?.required}
									/>
								</div>
								{errors?.[field?.name]?.[0] && (
									<div className="-mt-3 text-sm font-light text-red-600">
										{errors?.[field?.name]?.[0]}
									</div>
								)}
							</>
						);
					})}
				</div>
				<Buttonlink
					Text={
						loading ? <IsLoader white={true} className={"w-20"} /> : "SUBMIT"
					}
					disabled={loading}
					onClick={() => {}}
					className="mt-5 bg--primary text-white flex items-center justify-center Poppins text-[17px] hover:opacity-70 border-0 rounded-full shadow-none h-12 w-full uppercase"
				/>
			</form>
		</div>
	);
};
