import { gql } from "@apollo/client";

export default gql`
	query Seller_Plans ($is_deleted: Int, $user_id: Int) {
		seller_plans (is_deleted: $is_deleted, user_id: $user_id) {
			id
			name
			validity
			description
			price
			discount
			credits
			path
            is_deleted
		}
	}
`;
