import { gql } from "@apollo/client";

export default gql`
	query Auth_User_Payments {
		me {
			id
			seller_content_payment {
				id
				amount
				payer_user_id
				created_at
				tokens
				valid_till
				plan {
					id
					name
				}
			}
			seller_content_buyers {
				id
				buyer_id
				spend_by_buyer
				seller_content_id
				seller_id
				created_at
				seller {
					id
					creator {
						id
						creator_name
					}
				}
				seller_content {
					id
					type
					title
				}
			}
		}
	}
`;
