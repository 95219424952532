import React, { useState } from "react";
import { Blogskeleton, Buttonlink, Noconnection } from "components";
import GET_SG_USERS from "../../../graphql/SgQueries/SgUsers";
import { Query } from "@apollo/client/react/components";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import encryptfunction from "../../../helpers/Encrypt";
import { GoSearch } from "react-icons/go";
import { debounce } from "lodash";
import { Dropdown } from "semantic-ui-react";
import SELLER_CATEGORIES from "../../../graphql/queries/businessCategories";
import { useQuery } from "@apollo/client";

export const SellersListing = ({
	onLoadMore,
	add_products_actions,
	extractValidationErrors,
	isLoading,
}) => {
	const [name, setName] = useState("");
	const [selected, setSelected] = useState([]);

	const handleNameChange = debounce((value) => {
		setName(value);
	}, 1500);

	const { data } = useQuery(SELLER_CATEGORIES);

	const options = (data?.businessCategories?.data || [])?.map(
		(category, index) => ({
			key: index,
			text: category.name,
			value: Number(category.id),
		})
	);

	const selectCategories = (_, data) => {
		setSelected(data.value);
	};

	return (
		<>
			<div className="flex lg:flex-row flex-col gap-y-5 justify-between items-start gap-x-20">
				<div className="flex items-center px-2 bg-gray-100 w-full rounded-full md:px-5 h-14">
					<input
						placeholder="Start Typing to Search"
						className="flex-grow w-full pr-4 rounded-xl bg-gray-100 border-0 outline-none h-12 Regular"
						name="searchTitle"
						onChange={(e) => {
							const inputValue = e.target.value;
							handleNameChange.cancel();
							handleNameChange(inputValue);
						}}
					/>
					<GoSearch size={22} className="gray" />
				</div>
				<Dropdown
					className="flex-grow w-full pr-4 rounded-xl bg-gray-100 border-0 outline-none Regular"
					placeholder="Category Expertise"
					fluid
					multiple
					search={true}
					selection
					value={selected}
					options={options}
					onChange={selectCategories}
				/>
			</div>
			<Query
				query={GET_SG_USERS}
				fetchPolicy={"cache-and-network"}
				variables={{
					isSellerProfileCompleted: true,
					sellerName: name,
                    sellerCategories: (selected || [])?.length === 0 ? null: selected
				}}
			>
				{({ loading, error, data, fetchMore, refetch }) => {
					if (loading)
						return (
							<div className="grid grid-cols-1 my-5 gap-4  md:grid-cols-3 sm:grid-cols-2">
								<Blogskeleton />
								<Blogskeleton />
								<Blogskeleton />
							</div>
						);

					if (error) {
						extractValidationErrors(error);
						return (
							<div>
								<Noconnection />
							</div>
						);
					}
					if (data) {
						let users = data.sgUsers.data;
                        if ((users || [])?.length === 0) {
                            return (
                                <div className="w-full text-center mt-20 opacity-50 text-3xl">No Seller Profile Found!</div>
                            )
                        }
						return (
							<>
								<div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 gap-y-8 mt-10">
									{users?.map((user) => (
										<div
											className="block bg-white p-5 transition duration-500 ease-in-out transform shadow-[0px_4px_20px_0px_#0000001A] rounded-3xl"
											key={user.username}
										>
											<Link
												to={`/seller/${encryptfunction(user.id)}/${
													user.username
												}`}
												onClick={() =>
													add_products_actions({
														variables: {
															user_id: user.id,
														},
													})
												}
											>
												<div className="px-5 py-5 space-y-4">
													{user?.profile_photo ? (
														<img
															className="mx-auto border rounded-4 rounded-full h-[121px] w-[121px]"
															// onError={(e) => {
															// 	e.target.src = `${process.env.REACT_APP_MAIN_URL}/images/user_images/nobody_m.original.jpg`;
															// }}
															src={
																process.env.REACT_APP_MRO_URL +
																`/uploads/profile_images/` +
																user.profile_photo
															}
															alt={user.username}
														/>
													) : (
														<Avatar
															id={user.id}
															alt={user.firstname}
															className={"h-28 w-28 rounded-full mx-auto"}
															sx={{ height: 104, width: 104 }}
														/>
													)}
													<div className="space-y-2">
														<h4 className="text-center break-all Poppins text-new-color text-xl font-semibold">
															{user.firstname} {user.lastname}
														</h4>
														<p className="text-base text-center darkGray">
															@{user.username}
														</p>
													</div>
													<p className="text-lg line-clamp-3 text-new-color text-center w-full">
														{user.tagline}
													</p>
												</div>
												<div className="flex items-center shadow-[0px_4px_20px_0px_#0000001A] w-max mx-auto rounded-full justify-center px-10 py-2 gap-x-2">
													<p className="text-center primary text-lg font-semibold">
														Products/Services
													</p>
													<p className="text-center primary text-lg font-semibold">
														{user?.product_count}
													</p>
												</div>
											</Link>
										</div>
									))}
								</div>
								{!isLoading ? (
									<div className="mt-10 relative">
										{data?.sgUsers?.paginatorInfo?.hasMorePages && (
											<Buttonlink
												onClick={() => onLoadMore(data, fetchMore, refetch)}
												Text="Load More >>"
												className="border-0 font-semibold primary Poppins"
											/>
										)}
										<div className="absolute left-44 -top-44 lg:block hidden z-0">
											<svg
												width="265"
												height="265"
												viewBox="0 0 265 265"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clipPath="url(#clip0_3665_5750)">
													<path
														d="M192.254 79.2798L143.884 101.56L121.604 53.19C116.034 41.0975 102.787 36.2056 90.6941 41.7757C78.6015 47.3458 73.7097 60.5928 79.2798 72.6854L101.56 121.056L53.19 143.336C41.0975 148.906 36.2057 162.153 41.7758 174.246C47.3459 186.338 60.5929 191.23 72.6854 185.66L121.056 163.379L143.336 211.75C148.906 223.842 162.153 228.734 174.246 223.164C186.338 217.594 191.23 204.347 185.66 192.254L163.379 143.884L211.75 121.604C223.842 116.034 228.734 102.787 223.164 90.694C217.594 78.6015 204.347 73.7097 192.254 79.2798Z"
														fill="#FBE4D3"
														fill-opacity="0.7"
													/>
												</g>
												<defs>
													<clipPath id="clip0_3665_5750">
														<rect
															width="199.706"
															height="199.706"
															fill="white"
															transform="translate(0 83.5515) rotate(-24.7319)"
														/>
													</clipPath>
												</defs>
											</svg>
										</div>
									</div>
								) : (
									<div className="grid grid-cols-1 my-5 gap-4  md:grid-cols-3 sm:grid-cols-2">
										<Blogskeleton />
										<Blogskeleton />
										<Blogskeleton />
									</div>
								)}
							</>
						);
					}
				}}
			</Query>
		</>
	);
};
