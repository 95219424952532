import { Modal } from "components/Modal";
import { Form } from "components/form";
import { useState } from "react";
import { toast } from "react-toastify";
import CreateContactus from "../../../graphql/mutations/createContactus";
import client from "graphql/client";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import { extractObjectErrors } from "helpers/extractObjectErrors";

const modalFields = [
	{ name: "user_name", label: "Full Name", type: "text", required: true },
	{ name: "reason", label: "Company Name", type: "text", required: true },
	{ name: "user_email", label: "Email", type: "email", required: true },
	{
		name: "description",
		label: "Purpose/Requirement Details",
		type: "textarea",
		required: true,
	},
];

export const RequestModal = ({ modalRef }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState({});

	const handleCallback = async (data) => {
		setError({});
		setLoading(true);
		try {
			await client.mutate({
				mutation: CreateContactus,
				variables: {
					...data,
					ticket_type: "rfq",
					name: data?.user_name,
					email: data?.user_email,
				},
			});
			modalRef.current.close();
			toast.success("We have recieved your quote request!");
		} catch (error) {
			setError(extractObjectErrors(error));
			toast.error("Unable to submit quote request!");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal size="lg" ref={modalRef}>
			<div className="flex justify-center items-center w-full text-3xl mb-3 ">
				Request Quote
			</div>
			<Form
				fields={modalFields}
				handleCallback={handleCallback}
				loading={loading}
				errors={error}
			/>
		</Modal>
	);
};
