import pMinDelay from "p-min-delay";
import cookie from "js-cookie";
import decryptUrl from "./Decrypt";

const componentDelay = compoenet => {
  return pMinDelay(compoenet, 1000);
};

export { componentDelay };

export const getMimeTypeFromUrl = (url) => {

  const extension = url.split('.').pop().toLowerCase();

  return extension;
}

export const getTextLength = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const text = doc.body.textContent || "";
  return text.length;
}

export const getText = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const text = doc.body.textContent || "";
  return text;
}

export const setAuthExpiry = () => {
  const date = new Date();
  const seconds = date.getTime() + (1 * 24 * 60 * 60 * 1000);
  cookie.set("AUTH_TOKEN_EXPIRY", seconds?.toString());
}


export const generateRoomId = (id1, id2) => {
  const strId1 = String(decryptUrl(id1));
  const strId2 = String(decryptUrl(id2));
  
  return strId1 < strId2 ? `${strId1}_${strId2}` : `${strId2}_${strId1}`;
}


