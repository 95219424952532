import { gql } from "@apollo/client";

export default gql`
  query sgUsers($cursor: Int,$isSellerProfileCompleted:Boolean, $sellerName: String, $sellerCategories: [Int]) {
    sgUsers(
      page: $cursor
      first: 9
      seller_chk: true
      isSellerProfileCompleted:$isSellerProfileCompleted
      sellerName: $sellerName
      sellerCategories: $sellerCategories
      orderBy: [{ column: "updated_at", order: DESC }]
    ) {
      data {
        id
        firstname
        lastname
        username
        tagline
        is_seller
        become_seller
        is_active
        is_plan_expired
        product_count
        profile_photo
        isSellerProfileCompleted
      }
      paginatorInfo {
        total
        lastItem
        currentPage
        hasMorePages
      }
    }
  }
`;
