import { AnimateButton } from "components";

export const FirstScreen = () => {
	return (
		<div className="flex flex-col gap-y-3 justify-start items-start">
			<h2 className="text-xl mb-5">How you want to create your profile?</h2>
			<AnimateButton
				to={{ pathname: `/seller-profile/profile-information`, state: "individual" }}
				text={"Continue as an individual/Freelancer"}
				className={
					"flex bg--primary rounded-full white hover:text-white Poppins justify-between items-center h-12 px-5 w-full"
				}
			/>
			<AnimateButton
				to={{ pathname: `/seller-profile/profile-information`, state: "company" }}
				text={"As a Company"}
				className={
					"flex bg--primary rounded-full white hover:text-white Poppins justify-between items-center h-12 px-5 w-full"
				}
			/>
		</div>
	);
};
