export default function convertToSlug(Text)
{
    if (!Text) {
        return '';
    }
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}