import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
} from "@apollo/client";
import cookie from "js-cookie";
import { GRAPH_USER_AUTH_TOKEN } from "../config/constants";

let accessToken = cookie.get(GRAPH_USER_AUTH_TOKEN) || "";

// Create an HttpLink for the GraphQL API
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPH_URL });

// Middleware to set the authorization header
const authMiddleware = new ApolloLink((operation, forward) => {
  // Add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  }));

  return forward(operation);
});

// Create the Apollo Client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

// Function to refresh the access token if needed
const refreshToken = async () => {
  const token = await cookie.get(GRAPH_USER_AUTH_TOKEN);
  if (token !== accessToken) {
    accessToken = token || "";
  }
};

// Call this function whenever you need to ensure the token is fresh
export const updateTokenAndClient = async () => {
  await refreshToken();
};

export default client;
