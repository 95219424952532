import { gql } from "@apollo/client";

export default gql`
	query sellerContents(
		$page: Int
		$user_id: Int
		$type: String
		$title: String
		$status: Boolean
		$tag_ids: [Int]
	) {
		sellerContents(
			page: $page
			user_id: $user_id
			tag_ids: $tag_ids
			type: $type
			status: $status
			title: $title
			first: 10
			orderBy: [{ column: "created_at", order: DESC }]
		) {
			data {
				id
				user_id
				type
				paid
				tokens
				title
				remarks
				status
				view_count
				user {
					id
					firstname
					lastname
				}
				content {
					id
					cols {
						id
						type
						data
						caption
						row_id
					}
				}
				tags {
					id
					tag_title
				}
				created_at
				updated_at
			}
			paginatorInfo {
				total
				count
				perPage
				currentPage
				lastPage
				hasMorePages
			}
		}
	}
`;
