import { Query } from "@apollo/client/react/components";
import MY_PAYMENTS from "../../../graphql/queries/mePayments";
import Blogskeleton from "components/allcomponents/skeletons/blogskeleton";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import Noconnection from "components/allcomponents/noconnection";
import AnimateButton from "components/allcomponents/AnimteButton";

export const TransactionsHistory = ({ setShow }) => {
	return (
		<>
			<Query query={MY_PAYMENTS} fetchPolicy={"cache-and-network"}>
				{({ loading, error, data }) => {
					if (loading)
						return (
							<div className="grid grid-cols-1 my-8 gap-16 mt-5">
								<Blogskeleton />
							</div>
						);

					if (error) {
						extractValidationErrors(error);
						return (
							<div>
								<Noconnection />
							</div>
						);
					}

					const transactions = data?.me?.seller_content_payment;
					const totalTokens = transactions?.reduce(
						(accumulate, current) => accumulate + (current?.tokens || 0),
						0
					);

					return (
						<>
							<div className="flex justify-start w-full lg:mb-0 mb-10">
								<div className="bg--primary text-white lg:text-xl text-lg py-2 px-4 rounded-full hover:opacity-75">
									Balance: {totalTokens} Ȼ
								</div>
							</div>
							{(transactions || 0)?.filter((trans) => trans?.valid_till)
								?.length !== 0 ? (
								<div className="lg:text-4xl text-2xl font-light w-full text-center">
									Active Packages
								</div>
							) : (
								<div className="flex justify-center items-center mt-5 w-full flex-col gap-5">
									<div className="text-2xl text-center opacity-70">
										You didn't have any active plan!
									</div>
								</div>
							)}
							<div className="flex flex-col justify-start items-start w-full mt-5 gap-5">
								{(transactions || 0)?.length > 0 &&
									transactions
										?.filter((trans) => trans?.valid_till)
										?.map((plan) => {
											return (
												<div className="px-10 py-4 shadow-lg bg-white  w-full rounded-3xl">
													<div className="flex justify-between lg:flex-nowrap flex-wrap items-center">
														<div className="flex flex-col justify-start gap-4 items-start">
															<div className="font-bold lg:text-3xl text-2xl max-w-[500px]" style={{wordBreak:'break-word'}}>
																{plan?.plan?.name}
															</div>
															<div className="text-lg">
																Remaining Connects Balance: {plan?.tokens}
															</div>
															<div className="text-lg font-light italic primary">
																Subscribed On: {plan?.created_at}
															</div>
														</div>
														<div className="flex flex-col gap-3 justify-end items-end mt-2">
															<div className="font-bold text-4xl">
																{plan?.amount || 0} $
															</div>
															<div className="text-lg font-light italic primary">
																Valid till: {plan?.valid_till}
															</div>
														</div>
													</div>
												</div>
											);
										})}
							</div>
						</>
					);
				}}
			</Query>
			<AnimateButton
				text={"View Packages"}
				onClick={() => setShow(true)}
				className={
					"flex bg--primary rounded-full white hover:text-white Poppins justify-between items-center h-12 px-5 w-72 mt-5"
				}
			/>
		</>
	);
};
