import React from "react";
import contenthub from "../../assets/images/industries/contenthub.webp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { BsArrowDown, BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";

const accordionData = [
    {
        id: "panel1",
        title: "Pharma/Biotech: Educational Content",
        content: [
            "Articles: From clinical insights to market-shaping narratives",
            "Whitepapers: From research breakthroughs to industry thought leadership",
            "eBooks: From drug development to comprehensive market guides",
            "Guides: From regulatory expertise to audience-engaging content",
            "Playbooks: From molecule to market strategy blueprints"
        ]
    },
    {
        id: "panel2",
        title: "Informative Communication for Pharma/Biotech",
        content: [
            "Newsletters: From clinical trials updates to market trend analysis",
            "Blogs: From scientific breakthroughs to industry thought leadership"
        ]
    },
    {
        id: "panel3",
        title: "Engaging Stories and Testimonials",
        content: [
            "Case Studies: From impactful Pharma and Biotech case studies to showcasing your expertise",
            "Success Stories: From highlighting your achievements to demonstrating your potential"
        ]
    },
    {
        id: "panel4",
        title: "Comparative Analysis",
        content: [
            "Comparison Guides: From complex drug profiles to clear market positioning"
        ]
    }
];

const ContentHub = ({ handleChange, expanded, boxShadow }) => {
    return (
        <div className="grid grid-cols-12 gap-6">
            <div className="lg:col-span-8 md:col-span-6 col-span-12">
                <h1 className="lg:text-5xl capitalize text-4xl Poppins font-light text-new-color pb-5">
                    Welcome to Our <br /> Top-of-the-Marketing Funnel <br />{" "}
                    Content Hub Services
                </h1>
                <div className="flex flex-col gap-4 bg-white p-5 rounded-3xl divide-y" style={boxShadow}>
                    {accordionData.map((item) => (
                        <div key={item.id}>
                            <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)} className="overflow-hidden !shadow-none">
                                <AccordionSummary expandIcon={<BsPlusLg className="ml-5 primary" size={28} />}>
                                    <p className="text-lg font-semibold Poppins text-[#5ABD77]">{item.title}</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul className="list-disc space-y-2">
                                        {item.content.map((content, index) => (
                                            <li key={index} className="text-lg Poppins font-normal text-new-color">
                                                <span className="text-lg Poppins font-bold text-new-color">{content.split(":")[0]}:</span> {content.split(":")[1]}
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                </div>
                <Link to={'/contact-us'} className="flex mt-8 justify-between bg--lightBlue px-10 py-4 rounded-full items-center">
                    <p className="font-normal Poppins text-lg text-new-color">
                        Discover what a 6-month plan looks like for your company.
                    </p>
                    <BsArrowDown className="primary" size={22} />
                </Link>
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-12">
                <div className="h-full">
                    <img src={contenthub} alt="contenthub" className="object-cover object-right h-[calc(100%-50px)] w-full" />
                </div>
            </div>
        </div>
    );
};

export default ContentHub;
