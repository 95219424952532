import { Modal } from "components/Modal";
import { useEffect, useRef, useState } from "react";
import AnimateButton from "components/allcomponents/AnimteButton";
import Emitter from "services/emitter-service";
import { useSelector } from "react-redux";
import client from "graphql/client";
import { toast } from "react-toastify";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import IsLoader from "../IsLoader";
import SELLER_CONTENT_ENROLL from "../../../graphql/mutations/enrollSellerContent";
import { InfluencerPlan } from "./InfluencerPlan";
import MyAuthUserAction from "store/actions/authUserAction";

export const TokenModal = () => {
	const modalRef = useRef(null);
	const [data, setData] = useState({});
	const userInfo = useSelector(
		(state) => state.AuthUserReducer?.authUserInformation
	);
	const [loading, setLoading] = useState(false);

	const handleModalOpen = (data) => {
		setData(data);
		modalRef.current.open();
	};

	const handlePurchase = async () => {
		if (Number(userInfo?.connects) < Number(data?.amount) || loading) return;
		try {
			setLoading(true);
			await client.mutate({
				mutation: SELLER_CONTENT_ENROLL,
				variables: { sellerContentId: data?.id, tokens: Number(data?.amount) },
			});
			await MyAuthUserAction.getAuthUserInformation();
			toast.success("You can now access the content!");
			Emitter.emit("refetchContent");
			modalRef.current.close();
		} catch (error) {
			extractValidationErrors(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		Emitter.on("tokenModal", handleModalOpen);
		return () => {
			Emitter.off("tokenModal", handleModalOpen);
		};
	}, []);
	
	return (
		<Modal size={"md"} ref={modalRef}>
			<>
				<div className="text-4xl font-light w-full text-center">
					Buy Content
				</div>
				<div className="font- text-xl primary mt-9">
					Available Balance: {userInfo?.connects} Ȼ
				</div>

				<div className="lg:px-10 px-2 py-4 bg-white border-2 border--primary primary w-full rounded-3xl mt-5">
					<div className="flex justify-between items-center">
						<div className="flex flex-col justify-start gap-4 items-start">
							<div className="font-bold text-2xl sm:text-3xl">Buy in {data?.amount} Ȼ</div>
						</div>
						<div className="flex flex-col gap-3 justify-end items-end">
							<button
								disabled={
									Number(userInfo?.connects) < Number(data?.amount) || loading
								}
								onClick={() => handlePurchase()}
								className="px-3 disabled:py-0 tracking-wider disabled:opacity-70 hover:opacity-80 rounded-full py-2 border-2 border--primary"
							>
								{loading ? (
									<IsLoader className="w-[60px]" white={false} />
								) : (
									Number(userInfo?.connects) < Number(data?.amount) ? "Insufficient connects": "Purchase"
								)}
							</button>
						</div>
					</div>
				</div>
				<InfluencerPlan creatorId={data?.creator_id} userInfo={userInfo} modalRef={modalRef} />
				{Number(userInfo?.connects) < Number(data?.amount) && (
					<div className="text-center opacity-75 px-20 mt-5 text-xl text-new-color">
						You don't have enough connects. Buy one of our plans, and get
						connects!
					</div>
				)}
				<div className="flex justify-end items-end w-full">
					<AnimateButton
						text={"Packages"}
						onClick={() => {
							Emitter.emit("offersModal");
						}}
						className={
							"flex bg--primary rounded-full white hover:text-white Poppins justify-between items-center h-12 px-5 w-56 mt-5"
						}
					/>
				</div>
			</>
		</Modal>
	);
};
