import { Query } from "@apollo/client/react/components";
import IsLoader from "components/allcomponents/IsLoader";
import { useState } from "react";
import PAYMENT_PLANS from "../../../../graphql/queries/getSellerPlans";
import SELLER_PROFILE_ENROLL from "../../../../graphql/mutations/enrollSellerProfile";
import Blogskeleton from "components/allcomponents/skeletons/blogskeleton";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import Noconnection from "components/allcomponents/noconnection";
import MyAuthUserAction from "store/actions/authUserAction";
import client from "graphql/client";
import { toast } from "react-toastify";
import Emitter from "services/emitter-service";

export const InfluencerPlan = ({ creatorId, userInfo, modalRef }) => {
	const [loader, setLoader] = useState(false);

	const handleSubscribe = async () => {
		try {
			setLoader(true);
			const json = await client.mutate({
				mutation: SELLER_PROFILE_ENROLL,
				variables: { seller_id: Number(creatorId) },
			});
			await MyAuthUserAction.getAuthUserInformation();
			toast.success(
				json?.data?.chargePaymentViaTokens?.message ||
					"Congratulations. You have now subscribed to the seller's exclusive content"
			);
			Emitter.emit("refetchContent");
			modalRef.current.close();
		} catch (error) {
			extractValidationErrors(error);
		} finally {
			setLoader(false);
		}
	};

	return (
		<Query
			query={PAYMENT_PLANS}
			variables={{ is_deleted: 0, user_id: Number(creatorId) }}
			fetchPolicy={"cache-and-network"}
		>
			{({ loading, error, data }) => {
				if (loading)
					return (
						<div className="grid grid-cols-1 my-8 h-28 gap-16 mt-5">
							<Blogskeleton />
						</div>
					);
				if (error) {
					extractValidationErrors(error);
					return (
						<div>
							<Noconnection />
						</div>
					);
				}

				const plan = data?.seller_plans?.[0];
				let validity = "year";

				switch (plan?.validity) {
					case "W":
						validity = "week";
						break;
					case "M":
						validity = "month";
						break;
					default:
						validity = "year";
						break;
				}

				if (!plan) {
					return null;
				}

				return (
					<>
						<div className="flex items-center gap-x-3 mt-5">
							<div className="h-px flex-grow bg-gray-600" />
							<p className="flex-shrink-0">Or</p>
							<div className="h-px flex-grow bg-gray-600" />
						</div>
						<div className="text-xl primary mt-5">
							Subscribe to Influencer's Plan
						</div>
						<p className="text-sm mt-5 text-new-color">
							For one {validity}, you'll have exclusive access to your favorite
							influencer's content. Your subscription will automatically renew
							every {validity}, but you can easily unsubscribe anytime if you
							choose.
						</p>
						<div className="sm:px-10 px-2 py-4 bg-white border-2 border--primary primary w-full rounded-3xl mt-5">
							<div className="flex justify-between lg:flex-nowrap flex-wrap items-center gap-y-2">
								<div className="flex flex-col justify-start gap-4 items-start">
									<div className="font-bold sm:text-xl text-lg">
										<span className="font-bold sm:text-2xl text-xl mr-2">{plan?.name}</span>
										({plan?.credits} Ȼ / {validity})
									</div>
								</div>
								<div className="flex flex-col gap-3 justify-end items-end">
									<button
										disabled={
											Number(userInfo?.connects || 0) < Number(plan?.credits) ||
											loader
										}
										onClick={() => handleSubscribe()}
										className="px-3 disabled:py-0 tracking-wider disabled:opacity-70 hover:opacity-80 rounded-full py-2 border-2 border--primary"
									>
										{loader ? (
											<IsLoader className="w-[60px]" white={false} />
										) : (
											Number(userInfo?.connects || 0) < Number(plan?.credits) ? "Insufficient balance": "Subscribe"
										)}
									</button>
								</div>
							</div>
						</div>
					</>
				);
			}}
		</Query>
	);
};
