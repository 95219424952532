import { DraftEditor, IsLoader, SelectTags, Buttonlink } from "components";
import React, { Component } from "react";
import { IoIosArrowBack } from "react-icons/io";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { toast } from "react-toastify";
import client from "../../../graphql/client";
import POST_QUESTION_MUTATION from "../../../graphql/mutations/postQuestionMutation";
import { connect } from "react-redux";
import { Tab } from "@headlessui/react";
import axios from "axios";
import {
    GRAPH_USER_AUTH_TOKEN
} from "../../../config/constants";
import cookie from "js-cookie";
import { Image } from "semantic-ui-react";
import "../../../assets/css/semantic.css";
import Resizer from "react-image-file-resizer";
import QUESTIONS from "../../../graphql/queries/getQuestions";
import { gql } from '@apollo/client';
import { extractValidationErrors } from "../../../helpers/extractValidationErrors";
import { AiFillDelete } from "react-icons/ai";
import { getMimeTypeFromUrl, getTextLength } from "helpers";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

class PostDiscussion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            loading: false,
            images: [],
            selectedImages: [],
            path: [],
            pictures: [],
            originalLink: [],
            id: null,
            CountValue: 0,
            maxTextLimit: 500,
        };
    }

    componentDidMount() {
        if (this.props.question?.question?.length !== undefined) {
            this.setState({ CountValue: getTextLength(this.props.question.question) })
        }
        window.scrollTo(0, 0);
        if (this.props.question) {
            let Question = this.props.question;
            const publicImages = Question?.attachments.map(item => item.public_image) ?? [];
            this.setState({
                id: Question.id,
                title: Question.question,
                description: Question.description,
                path: publicImages
            }, function () {
                let topics = [];
                let invites = [];
                Question?.categories?.map(category => {
                    topics.push(category.id)
                })
                Question?.invites?.map(category => {
                    invites.push(category.id)
                })
                this.props.handleUsersChange(invites)
                this.props.handleCategorySearch(topics);
                this.props.handleTagSearch(Question.tags);
                this.props.handleEmailChange("");
            })
            // console.log("Question", Question)
        }
    }

    _handleInputDetailsChange = (e) => {
        this.setState({
            ...this.state,
            title: e,
        });
    };

    hanleEditorChange = (event) => {
        this.setState({
            description: event.target.value,
        });
    };

    uploadImage = (image, data) => {
        const img_mime = image.type.split("/");
        const img_name = image.name.split(".");

        const newPicture = {
            name: img_name[0],
            size: image.size,
            type: img_mime[0],
            mime: img_mime[1],
            url: data && data.path ? data.path : null,
        };

        this.setState((prevState) => ({
            pictures: [...prevState.pictures, newPicture],
        }));
    };

    image_upload_axios = async (files) => {
        return new Promise(async (resolve, reject) => {
            try {
                const token = cookie.get(GRAPH_USER_AUTH_TOKEN);
                const headers = {
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                };
                const axiosInstance = axios.create();
                axiosInstance.defaults.onUploadProgress = (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    // Update the progress bar or trigger a callback function to update it
                    // Example: this.updateProgressBar(progress);
                };

                const uploadPromises = files.map(async (file) => {
                    const formdata = new FormData();
                    formdata.append("file", file);
                    formdata.append("name", "pl_discussions");
                    formdata.append("type", "image");

                    const res = await axiosInstance.post(`${process.env.REACT_APP_MAIN_URL}/api/s3upload`, formdata, { headers });

                    if (res.data.success) {
                        this.uploadImage(file, res.data);
                    }
                });

                // Wait for all upload promises to complete
                await Promise.all(uploadPromises);

                this.setState({ selectedImages: [] });
                resolve(true); // Resolve the promise with a success flag
            } catch (error) {
                console.error(error);
                reject(error); // Reject the promise with the error
            }
        });
    };

    fileChangedHandler = (file) => {
        if (!file) return;

        try {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "webp",
                100,
                0,
                (uri) => {
                    this.setState((prevState) => ({
                        selectedImages: [...prevState.selectedImages, uri],
                    }));
                },
                "file",
                150,
                150
            );
        } catch (err) {
            console.log(err);
        }
    };

    handleCallback = (childData) => {
        const { path, images, originalLink } = this.state;
        const validExtensions = [".jpg", ".jpeg", ".png", ".webp"];
        if ((Object.keys(childData || {}))?.length === 0) {
            return;
        }

        if ((((path || [])?.length || 0) + ((images || [])?.length || 0) + (Object.keys(childData || {}))?.length) > 3) {
            this.notify("You can only select up to 3 images", "error");
            return;
        }

        const validFiles = Object.values(childData).filter((file) =>
            file && file.name && validExtensions.includes(file.name.substring(file.name.lastIndexOf(".")).toLowerCase())
        );

        if (validFiles.length === 0) {
            this.notify("Only files with the extensions .jpg, .jpeg, .png, and .webp are allowed", "error");
            return;
        }

        validFiles.forEach((file) => {
            this.fileChangedHandler(file);
        });

        const imageEntries = validFiles.map((file, index) => ({
            url: URL.createObjectURL(file),
            name: file.name,
            key: index,
        }));

        this.setState((prevState) => ({
            images: [...prevState.images, ...validFiles],
            originalLink: [...originalLink, ...imageEntries],
        }));
    };


    getAlerts = () => {

        client
            .query({
                query: gql`
                query MyNotifications(
                    $cursor: Int
                    $getAll: Boolean
                    $getLikes: Boolean
                    $getAnswers: Boolean
                    $getComments: Boolean
                    $getfollowers: Boolean
                ) {
                    me {
                        id
                        notifications(
                            first: 1000
                            page: $cursor
                            GetAll: $getAll
                            GetLikes: $getLikes
                            GetAnswers: $getAnswers
                            GetComments: $getComments
                            Getfollowers: $getfollowers
                        ) {
                            data {
                                id
                                read_at
                            }
                        }
                    }
                }
            `,
                variables: {
                    cursor: 1,
                    getAll: true,
                },
                fetchPolicy: "network-only"
            })
            .then((res) => {


                let count = res.data.me.notifications.data.filter(
                    (o) => o.read_at === null
                ).length;
                this.setState({ alerts: count });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    handleOnSubmit = () => {
        this.setState({ loading: true });
        const {
            title,
            description,
            selectedImages
        } = this.state;

        const {
            selectedTags,
            categories,
        } = this.props;

        if (selectedTags.length > 0 || title || description || categories.length > 0) {
            if (selectedImages.length !== 0) {
                // Upload the resized image and wait for the response
                this.image_upload_axios(selectedImages)
                    .then((uploadSuccess) => {
                        this.sendPostRequest().then(r => {
                            this.setState({ selectedImages: [] })
                        });
                    })
                    .catch((error) => {
                        // Handle any errors during image upload
                    });
            } else {
                this.sendPostRequest()
            }
        }
    };

    sendPostRequest = async () => {
        const {
            id,
            title,
            description,
            images,
        } = this.state;

        const {
            selectedTags,
            categories,
            selectedUsers,
            typedEmail,
            history,
            handleTagSearch,
            handleCategorySearch,
            handleUsersChange,
            handleEmailChange,
        } = this.props;
        const updatedCategories = categories.map(category => parseInt(category, 10));
        const tagIds = selectedTags.map((tag) => Number(tag.id));
        const updatedUserIds = selectedUsers.map(user_id => parseInt(user_id, 10));
        let Question = this.props.question;
        const publicImages = (Question?.attachments || [])?.filter(item => this.state.path.includes(item.public_image))?.map((i) => {
            return {...i, mime: getMimeTypeFromUrl(i?.url)}
        }) ?? [];
        try {
            const response = await client.mutate({
                mutation: POST_QUESTION_MUTATION,
                variables: {
                    id: parseInt(id),
                    title,
                    description,
                    categories: updatedCategories,
                    user_ids: updatedUserIds,
                    tag_ids: tagIds,
                    emails: typedEmail,
                    status: 1,
                    pictures: [...(this.state.pictures || []), ...(publicImages || [])]
                },
                refetchQueries: [{ query: QUESTIONS }],
            });

            // Handle the response as needed
            this.getAlerts();
        } catch (error) {
            console.log(error);
            extractValidationErrors(error).forEach((error) =>
                this.notify(error, "error")
            );
        } finally {
            this.setState({ loading: false }, () => {
                this.notify("Posted successfully", "success");
            });
            handleTagSearch([]);
            handleCategorySearch([]);
            handleUsersChange([]);
            handleEmailChange("");
            history.push({
                pathname: "/discussions",
                state: { refresh: true },
            });
        }
    }

    removeImage = (image) => {
        // Extract the key and URL from the image object
        const { key, url, name } = image;
        // Filter out the image from the state based on the key
        const updatedImages = this.state.images.filter((img) => img.name !== name);
        const updatedOriginalLink = this.state.originalLink.filter((item) => item.name !== name);
        const updatedSelectedImages = this.state.selectedImages.filter((item, index) => index !== key);

        this.setState({
            images: updatedImages,
            originalLink: updatedOriginalLink,
            selectedImages: updatedSelectedImages
        });
    }

    removeImagePath = (image_path) => {
        const paths = this.state.path?.filter((pa) => pa !== image_path);
    
        this.setState({
          ...this.state,
          path: paths,
        });
    };    

    notify = (message = "", type = "error") => {
        if (type === "error") {
            return toast.error(message, { position: "bottom-right" });
        }
        toast.success(message, { position: "bottom-right" });
    };

    render() {

        const { images, description, CountValue, maxTextLimit, originalLink, selectedImages } = this.state;

        /*  console.log("images", images);
          console.log("originalLink", originalLink);
          console.log("selectedImages", selectedImages);
          console.log("selectedImages", selectedImages.length);*/
        const { selectedTags, categories } = this.props;
        const canSubmit =
            selectedTags.length === 0 ||
            categories.length === 0 ||
            maxTextLimit < CountValue ||
            CountValue === 0 ||
            description.length === 0;
        return (
            <div className="container">
                {
                    this.props.history &&
                    <div className="relative">
                        <Buttonlink
                            Text="back"
                            Icon={<IoIosArrowBack />}
                            onClick={() => this.props.history.goBack()}
                            className="left-0 z-10 flex items-center justify-center px-4 border-0 shadow-none lg:absolute bg--lightGray hover:bg--lightGray focus:bg--lightGray Regular h-9 rounded--full darkGray w-28 hover:darkGray hover:opacity-80"
                        />
                    </div>
                }

                <div className="add-discussion lg:mt-0 mt-10">
                    <div className="grid gap-4 lg:grid-cols-6">
                        <div className="lg:col-start-2 lg:col-span-4 ...">
                            <Tab.Group>
                                <Tab.List
                                    className="flex max-w-full p-2 mx-auto mb-10 bg-gray-100 lightPrimary lg:max-w-lg rounded-xl">
                                    <Tab
                                        className={({ selected }) =>
                                            classNames(
                                                "w-full text-white p-0 m-0 bg-gray-200 Regular px-4 hover:bg--lightPrimary hover:opacity-70 cursor-pointer rounded-full h-10 flex items-center justify-center",
                                                // "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                                                selected ? "bg--primary" : "bg--lightPrimary primary"
                                            )
                                        }
                                    >
                                        Public
                                    </Tab>
                                    <Tab
                                        disabled
                                        className={({ selected }) =>
                                            classNames(
                                                "w-full text-white p-0 hidden m-0 bg--lightPrimary Regular px-4 hover:bg--lightPrimary hover:opacity-70 cursor-pointer rounded-r-full h-10 items-center justify-center",
                                                selected ? "bg--primary" : "bg--lightPrimary primary"
                                            )
                                        }
                                    >
                                        Private
                                    </Tab>
                                </Tab.List>
                                <Tab.Panels className="mt-2">
                                    <Tab.Panel className="bg-white rounded-xl">
                                        <p className="ml-2 text-lg">Title</p>
                                        <TextareaAutosize
                                            minRows={3}
                                            className="w-full p-4 border outline-none rounded--lg"
                                            placeholder="Type your Title"
                                            onChange={this.hanleEditorChange}
                                            name="title"
                                            value={description}
                                            maxLength={255}
                                        />
                                        <div className={'flex justify-between'}>
                                            <p className="mt-4 ml-2 text-lg">Description</p>
                                            {
                                                CountValue !== 0 &&
                                                <p className={`mt-4 ml-2 text-lg ${CountValue > maxTextLimit ? "danger" : "primary"}`}>{CountValue} /{maxTextLimit} </p>
                                            }
                                        </div>
                                        <DraftEditor
                                            maxTextLimit={maxTextLimit}
                                            path={'post_question'}
                                            maximumCharacter={(textCount) => this.setState({ CountValue: textCount })}
                                            title={this.props.question?.question}
                                            onChange={this._handleInputDetailsChange}
                                            parentCallback={this.handleCallback}
                                        />
                                            <div className="mt-2 space-y-3">
                                                    <p className="mt-2 ml-2 text-lg">Allowed Media Uploads (Up to 3 Images)</p>
                                                    <div
                                                        className="grid grid-cols-3 gap-x-5 relative p-2 rounded transition duration-300" // Apply hover styles here
                                                    >
                                                {originalLink?.length > 0 &&
                                                    originalLink.map((tempLinks, index) => (
                                                        <div className="flex flex-col gap-2 relative" key={index}>
                                                            <img src={tempLinks.url} />
                                                            <span className="input-label">{tempLinks.name}</span>
                                                            <div className="absolute -top-[10px] w-6 h-6 bg-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] rounded-full flex justify-center items-center -right-[10px] cursor-pointer"> {/* Apply hover styles here */}
                                                                <AiFillDelete className="text-orange" onClick={() => this.removeImage(tempLinks)} />
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                {this.state.path.length > 0 &&
                                                    this.state.path.map((imagePath, index) => (
                                                        <div className="flex flex-col gap-2 relative" key={index}>
                                                            <img src={imagePath} />
                                                            <div className="absolute -top-[10px] w-6 h-6 bg-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] rounded-full flex justify-center items-center -right-[10px] cursor-pointer"> {/* Apply hover styles here */}
                                                                <AiFillDelete className="text-orange" onClick={() => this.removeImagePath(imagePath)} />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                        </div>
                                        </div>
                                        <SelectTags />
                                        <button
                                            disabled={canSubmit}
                                            type={"submit"}
                                            onClick={this.handleOnSubmit}
                                            className={`flex items-center justify-center flex-grow w-full h-12 px-5 text-white border-0 shadow-none bg--primary hover:color-white Regular ${!canSubmit
                                                ? 'hover:opacity-70' : 'opacity-30'
                                                } focus:bg--primary rounded--full`}>
                                            {this.state.loading ? (
                                                <IsLoader className="h-full mx-auto" white={true} />
                                            ) : (
                                                "Post"
                                            )}
                                        </button>
                                    </Tab.Panel>
                                    <Tab.Panel className="p-3 bg-white rounded-xl">2</Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedTags: state.DiscussionSearchReducer.selectedTags,
    categories: state.DiscussionSearchReducer.categories,
    typedEmail: state.DiscussionSearchReducer.typedEmail,
    selectedUsers: state.DiscussionSearchReducer.selectedUsers,
});

const mapDispatchToProps = (dispatch) => {
    return {
        handleTagSearch: (tags) =>
            dispatch({ type: "SELECTED_TAGS", payload: tags }),
        handleCategorySearch: (data) =>
            dispatch({ type: "SELECTED_CATEGORY", payload: data }),
        handleUsersChange: (tags) =>
            dispatch({ type: "SELECTED_USER", payload: tags }),
        handleEmailChange: (email) =>
            dispatch({ type: "TYPE_EMAIL", payload: email }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDiscussion);
// export default PostDiscussion;
