import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { FaLinkedin } from "react-icons/fa";
import {
  GRAPH_USER_AUTH_TOKEN,
  LINKEDIN_URL_CALLBACK,
} from "../../../config/constants";
import client from "../../../graphql/client";
import cookie from "js-cookie";
import { gql } from "@apollo/client";
import { IsLoader } from "components";
import { setAuthExpiry } from "helpers";
import { useHistory } from "react-router-dom";

function LinkedInPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

    const { linkedInLogin } = useLinkedIn({
      clientId: "782cg1r81oi9cu",
      redirectUri: LINKEDIN_URL_CALLBACK,
      scope: ["r_liteprofile", "r_emailaddress"],
      onSuccess: (code) => {
        setIsLoading(true);
        setError('');
        client
          .mutate({
            mutation: gql`
              mutation get_linked_In($code: String!, $callback: String!, $type:String) {
                get_linked_In(code: $code, callback: $callback ,type:$type) {
                  token
                  error
                  message
                }
              }
            `,
            variables: {
              code: code,
              callback: LINKEDIN_URL_CALLBACK,
              type:'code'
            },
          })
          .then((response) => {
            let { error, message, token } = response.data.get_linked_In;
            if (error === true) {
              setError(message);
              setIsLoading(false);
            } else {
              cookie.set(GRAPH_USER_AUTH_TOKEN, token);
              setAuthExpiry();
              history.push('/');
              setIsLoading(false);
              dispatch({ type: "AUTH_USER_TOKEN", payload: token })
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setError(err);
          });
    },
    onError: (error) => {
      setError(error?.errorMessage);
      setIsLoading(false);
    },
  });

  return (
    <>
        <button
        type="button"
        disabled={isLoading}
          onClick={linkedInLogin}
          className="flex items-center capitalize justify-center gap-4 w-full h-12 !text-lg px-4 bg-[#F2F2F2] border rounded-full shadow-none hover:black darkGray focus:black hover:opacity-70 hover:bg-[#F2F2F2] text-new-color focus:bg-[#F2F2F2] rounded--full Poppins border-none"
        >
      {isLoading ? 
        <IsLoader
        className="h-full"
        />:
        <>
          <FaLinkedin size={22} color="#0966C2" />
          <span>Continue With Linkedin</span>
        </>
      }
      </button>
      {error &&
        <div className="primary">{error}</div>
      }
      </>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      handleAuthUser: (data) =>
      dispatch({ type: "AUTH_USER_TOKEN", payload: data }),
    };
};

export default connect(null, mapDispatchToProps)(LinkedInPage);
