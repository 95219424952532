import { gql } from "@apollo/client";

export default gql`
	mutation ChargePaymentViaTokens($seller_id: ID!) {
		chargePaymentViaTokens(seller_id: $seller_id) {
			success
			message
		}
	}
`;
