import { gql } from "@apollo/client";

export default gql`
	mutation ChargePayment(
		$name: String!
		$email: String
		$amount: Float!
		$payment_method_id: String!
		$plan_id: ID
		$model: String!
		$tokens: Int
	) {
		chargePayment(
			name: $name
			email: $email
			amount: $amount
			payment_method_id: $payment_method_id
			plan_id: $plan_id
			model: $model
			tokens: $tokens
		) {
			success
			message
			payment {
				id
				amount
				tokens
				valid_till
			}
		}
	}
`;
