import { Dialog } from "@headlessui/react";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FiX } from "react-icons/fi";

export const DialogBox = forwardRef(({ children, size }, ref) => {
	const [open, setOpen] = useState(false);

	useImperativeHandle(
		ref,
		() => {
			return {
				open() {
					setOpen(true);
					document.body.style.overflow = "hidden";
				},
				close() {
					setOpen(false);
					document.body.style.overflow = "auto";
				},
			};
		},
		[]
	);

	return (
		<Dialog
			as="div"
			open={open}
			className="relative z-[999]"
			onClose={() => {
				setOpen(false);
				document.body.style.overflow = "auto";
			}}
		>
			<div className="fixed inset-0 bg-black bg-opacity-25" />

			<div className="fixed inset-0 overflow-y-auto">
				<div className="flex items-center justify-center min-h-full p-4 text-center">
					<Dialog.Panel
						className={`w-full p-5 relative ${
							size === "lg"
								? "max-w-4xl"
								: size === "md"
								? "max-w-3xl"
								: "max-w-md"
						} overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-[30px]`}
					>
						<div
							onClick={() => {
								setOpen(false);
								document.body.style.overflow = "auto";
							}}
							className="flex cursor-pointer justify-end items-end"
						>
							<FiX size={22} />
						</div>
						{children}
					</Dialog.Panel>
				</div>
			</div>
		</Dialog>
	);
});
