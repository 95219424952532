import { AUTH_USER, GRAPH_USER_AUTH_TOKEN } from "config/constants";
import client from "graphql/client";
import cookie from "js-cookie";
export const extractObjectErrors = (error) => {
    try {
        const graphQLErrors = error?.graphQLErrors;
        if (graphQLErrors[0].message === "Unauthenticated.") {
            cookie.remove(GRAPH_USER_AUTH_TOKEN);
            cookie.remove(AUTH_USER);
            cookie.remove("AUTH_TOKEN_EXPIRY");
            localStorage.removeItem(AUTH_USER);
            client.clearStore();
            window.location.href = "/";
        }
        else if (graphQLErrors && graphQLErrors[0]?.extensions) {
            const validationErrors = graphQLErrors[0]?.extensions?.validation || {};
            return validationErrors;
        }
        else {
            return [graphQLErrors[0].message];
        }

    } catch (e) {
        console.log("validation errors not found: ", e);
    }

    return [];
};
