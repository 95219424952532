import { Modal } from "components/Modal";
import { useEffect, useRef, useState } from "react";
import PAYMENT_PLANS from "../../graphql/queries/getSellerPlans";
import { Query } from "@apollo/client/react/components";
import { extractValidationErrors } from "helpers/extractValidationErrors";
import Blogskeleton from "components/allcomponents/skeletons/blogskeleton";
import Noconnection from "components/allcomponents/noconnection";
import AnimateButton from "components/allcomponents/AnimteButton";
import Emitter from "services/emitter-service";
import { BsArrowRight, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { TransactionsHistory } from "./TransactionsHistory";

export const OffersModal = () => {
	const modalRef = useRef(null);
	const [amount, setAmount] = useState(5);
	const [show, setShow] = useState(false);
	const userInfo = useSelector(
		(state) => state.AuthUserReducer?.authUserInformation
	);
	const systemSettings = userInfo?.settings;

	const handleModalOpen = () => {
		setShow(true);
		setAmount(5);
		modalRef.current.open();
	};

	const handleActivate = (plan) => {
		modalRef.current.close();
		let dataToSend = {};
		if (plan?.id) {
			dataToSend = {
				name: `${userInfo?.firstname || ""} ${userInfo?.lastname || ""}`,
				email: userInfo?.email || "",
				model: "SellerContent",
				plan_id: plan?.id,
				amount: plan?.price,
			};
		} else {
			dataToSend = {
				name: `${userInfo?.firstname || ""} ${userInfo?.lastname || ""}`,
				email: userInfo?.email || "",
				model: "SellerContent",
				amount: Number(amount),
				tokens: Number(amount) * (systemSettings?.conversion_rate || 1),
			};
		}
		Emitter.emit("paymentModal", dataToSend);
	};

	useEffect(() => {
		Emitter.on("offersModal", handleModalOpen);
		return () => {
			Emitter.off("offersModal", handleModalOpen);
		};
	}, []);
	
	return (
		<Modal size={"md"} ref={modalRef}>
			{show ? (
				<>
					<div className="text-4xl font-light w-full text-center border-b pb-4">
						Choose your Package
					</div>
					<div className="flex justify-start my-3 gap-y-1 flex-col items-start">
						<button
							className="text-lg primary flex items-center gap-x-2"
							onClick={() => setShow(false)}
						>
							My Packages <BsArrowRight size={20} className="primary" />
						</button>
						<p className="text-[14px] text-gray-500">Buy connects of worth 5$ atleast to make payment*</p>
					</div>
					<div className="lg:px-10 px-5 py-4 mt-5 bg-white border-2 border--primary primary w-full rounded-3xl">
						<div className="flex justify-between items-center">
							<div className="flex flex-col justify-start gap-4 items-start">
								<div className="font-bold lg:text-3xl text-xl">Recharge Connects</div>
								<div className="text-lg font-light primary italic flex justify-center gap-x-2 items-center">
									<button
										onClick={() =>
											Number(amount) === 5
												? setAmount(5)
												: setAmount(Number(amount) - 1)
										}
									>
										<BsChevronLeft size={18} />
									</button>
									<input
										type="number"
										onChange={(e) => {
											if (Number(e.target.value) >= 0) {
												if (Number(e.target.value) >= 1000) {
													return setAmount(1000);
												}
												setAmount(e.target.value);
											}
										}}
										value={amount}
										placeholder="0"
										className="max-w-20 text-bold text-center hide-spinners bg-transparent"
									/>
									<button
										onClick={() => {
											if (Number(amount) >= 1000) {
												return setAmount(1000);
											}
											setAmount(Number(amount) + 1);
										}}
									>
										<BsChevronRight size={18} />
									</button>
									$
								</div>
							</div>
							<div className="flex flex-col gap-3 justify-end items-end">
								<div className="font-bold lg:text-4xl text-2xl">
									{Number(amount) * (systemSettings?.conversion_rate || 0)} Ȼ
								</div>
								<button
									disabled={Number(amount) < 5}
									onClick={() => handleActivate()}
									className="px-3 tracking-wider disabled:opacity-70 hover:opacity-80 rounded-full py-1 border-2 border--primary"
								>
									Purchase
								</button>
							</div>
						</div>
					</div>
					<Query
						query={PAYMENT_PLANS}
						variables={{ is_deleted: 0, user_id: null }}
						fetchPolicy={"cache-and-network"}
					>
						{({ loading, error, data }) => {
							if (loading)
								return (
									<div className="grid grid-cols-1 my-8 gap-16 mt-5">
										<Blogskeleton />
									</div>
								);
							if (error) {
								extractValidationErrors(error);
								return (
									<div>
										<Noconnection />
									</div>
								);
							}

							return (
								<div className="flex flex-col justify-start items-start w-full mt-5 gap-5">
									{data?.seller_plans?.map((plan) => {
										let validity = "year";
										switch (plan?.validity) {
											case "W":
												validity = "week";
												break;
											case "M":
												validity = "month";
												break;
											default:
												validity = "year";
												break;
										}
										return (
											<div className="lg:px-10 px-5 py-4 bg--primary text-white w-full rounded-3xl">
												<div className="flex justify-between lg:flex-nowrap flex-wrap gap-y-2 items-center w-full">
													<div className="flex flex-col justify-start flex-shrink-0 gap-2 items-start flex-grow">
														<p style={{wordBreak: 'break-word'}} className="font-bold lg:text-3xl text-2xl max-w-[500px] text-white">
															{plan?.name}
														</p>
														<p className="text-lg font-light italic text-white">
															{plan?.credits} connects/ {validity}
														</p>
													</div>
													<div className="flex flex-col gap-3 flex-shrink-0 flex-grow justify-end items-end">
														<p className="font-bold text-4xl text-white">
															{plan?.price || 0} $
														</p>
														<button
															onClick={() => handleActivate(plan)}
															className="px-3 tracking-wider hover:opacity-80 rounded-full py-1 border-2 border-white"
														>
															Subscribe
														</button>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							);
						}}
					</Query>
				</>
			) : (
				<TransactionsHistory setShow={setShow} />
			)}
		</Modal>
	);
};
