import diamond from "assets/images/our-team/diamond.png";
import star from "assets/images/our-team/star.png";
import light from "assets/images/our-team/light.png";
import tick from "assets/images/our-team/tick.png";
import plane from "assets/images/our-team/plane.png";
import layer from "assets/images/our-team/layer.png";
import office from "assets/images/our-team/office.png";
import growth from "assets/images/our-team/growth.png";
import models from "assets/images/our-team/models.png";
import ballon from "assets/images/our-team/ballon.png";
import wellness from "assets/images/our-team/wellness.png";
import pink from "assets/images/our-team/pink.png";
import blue from "assets/images/our-team/blue.png";
import multiply from "assets/images/our-team/multiply.png";
import pattern1 from "assets/images/SuccessStories/pattern_one.png";

export const TeamContent = () => {
    const qualities = [
        {
            name: "Drive Excellence:",
            desc: "Deliver outstanding results that exceed client expectations.",
            img: diamond,
        },
        {
            name: "Embody Our Values:",
            desc: "Embody Our Values: Integrate our core principles into every action and decision.",
            img: star,
        },
        {
            name: "Lead by Example:",
            desc: "Inspire and support colleagues, fostering a culture of mutual growth.",
            img: light,
        },
    ];

    const values = [
        "Cultivate a culture where every team member is both a learner and a leader.",
        "Embrace the Power of Differences, celebrating diversity as our strength.",
        "Our values are the foundation of our collaborative spirit.",
        "They define how we interact, innovate, and grow.",
        "Prioritize long-term impact over short-term gains.",
        "Doing work that makes us proud.",
    ];

    return (
        <div className="flex flex-col justify-center items-center mt-10 relative">
            <div className="container mx-auto px-10">
                <img
                    src={pink}
                    alt="pink"
                    className="w-max lg:block hidden absolute -top-[100px] left-[220px]"
                />
                <img
                    src={blue}
                    alt="blue"
                    className="w-[40px] lg:block hidden absolute -top-[30px] right-[250px]"
                />
                <p className="font-light text-center lg:text-5xl text-3xl">
                    Cultivating Leadership at Every Level
                </p>
                <p className="text-[18px] max-w-[810px] mx-auto text-center leading-[25px] mt-10">
                    At Procurement League, we believe every team member is a
                    custodian of our culture and a leader in their own right. We
                    empower our employees to:
                </p>
            </div>
            <div className="grid lg:grid-cols-3 container grid-cols-1 w-full mt-16">
                {qualities?.map((quality) => {
                    return (
                        <div className="flex flex-col justify-between mx-auto items-center gap-y-5 max-w-[400px]">
                            <img
                                src={quality?.img}
                                alt={quality?.name}
                                className="h-[70px]"
                            />
                            <div className="font-light text-center lg:text-3xl text-2xl leading-[35px] text-[30px] primary">
                                {quality?.name}
                            </div>
                            <div className="text-center leading-[25px] text-[18px]">
                                {quality?.desc}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="TeamColleagueBanner w-full relative bg-blue-100 lg:p-20 p-5 mt-16">
                <img
                    src={multiply}
                    alt="multiply"
                    className="w-max lg:block hidden absolute top-10 left-[210px]"
                />
                <div className="absolute top-0 bottom-0 right-0 left-0 bg-[#233862] bg-opacity-80" />
                <div className="container relative flex flex-col items-center justify-center gap-y-10">
                    <div className="font-light text-center text-[30px] leading-[35px] text-[#B5E2FF] z-10">
                        Our Values: The Pillars of Our Success
                    </div>
                    <div className="text-[18px] text-center max-w-[800px] leading-[25px] z-10 text-white">
                        At Procurement League, we believe every team member is a
                        custodian of our culture and a leader in their own
                        right. We empower our employees to:
                    </div>
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-y-5 gap-x-9 z-10 mt-4">
                        {values?.map((value, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`flex flex-col gap-y-3 justify-center items-center ${
                                        index === 5 ? "px-24" : "px-10"
                                    } py-5 rounded-[50px] bg-white`}
                                >
                                    <img src={tick} className="w-[38px]" />
                                    <div className="font-bold text-[18px] leading-[25px] text-center">
                                        {value}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="mt-16 mb-10 container font-semibold text-[30px] leading-[35px] text-center">
                We believe in fostering a vibrant, collaborative, and innovative
                work environment. Here's what makes our workplace unique:
            </div>
            <div className="relative w-full">
				<img
					src={pattern1}
					alt="Subtract"
					className="absolute w-[350px] z-10 right-0 xl:block hidden"
				/>
                <div className="relative container flex justify-center items-start xl:gap-y-0 gap-y-10 flex-col z-50">
					<img src={layer} alt="layer" className="w-full xl:block hidden" />
					<div className="top-[10%] left-[15%] xl:absolute xl:block hidden xl:max-w-[510px]">
						<div className="font-bold text-[18px] leading-[25px]">
							Team Building Activities:
						</div>
						<ul className="list-disc">
							<li className="text-[18px] leading-[25px]">
								Monthly "Innovation Jams" where teams brainstorm creative
								solutions.
							</li>
							<li className="text-[18px] leading-[25px]">
								Quarterly outdoor adventures to strengthen bonds and refresh
								minds.
							</li>
							<li className="text-[18px] leading-[25px]">
								Annual "Meet-ups" - celebrating achievements and setting future
								goals.
							</li>
						</ul>
					</div>
                    <img
                        src={plane}
                        alt="plane"
                        className="w-max xl:absolute xl:block hidden -left-[10%] -top-[1.5%]"
                    />
                    <img
                        src={office}
                        alt="office"
                        className="w-max xl:absolute xl:block hidden top-[2%] right-[7%]"
					/>
                    <img
                        src={growth}
                        alt="growth"
                        className="w-max xl:absolute xl:block hidden top-[25%] -left-[10%]"
					/>
					<div className="top-[30%] left-[15%] xl:absolute xl:max-w-[510px]">
						<div className="font-bold text-[18px] leading-[25px]">
							Professional Growth:
						</div>
						<ul className="list-disc">
							<li className="text-[18px] leading-[25px]">
								Mentorship programs pairing junior staff with industry veterans.
							</li>
							<li className="text-[18px] leading-[25px]">
								Regular skill-sharing workshops led by team members.
							</li>
							<li className="text-[18px] leading-[25px]">
								Generous conference and training budget for continuous learning.
							</li>
						</ul>
					</div>
					<img
						src={ballon}
						alt="ballon"
						className="w-max xl:absolute xl:block hidden top-[44%] left-[30%]"
					/>
					<img
						src={models}
						alt="models"
						className="w-max xl:absolute xl:block hidden top-[54%] -right-[6%]"
					/>
					<div className="xl:absolute top-[57%] right-[13%] xl:max-w-[440px]">
						<div className="font-bold text-[18px] leading-[25px]">
							Flexible Working Models:
						</div>
						<ul className="list-disc">
							<li className="text-[18px] leading-[25px]">
								Fully Remote Work Environment.
							</li>
							<li className="text-[18px] leading-[25px]">
								Customizable work hours to accommodate diverse lifestyles and peak
								productivity times.
							</li>
							<li className="text-[18px] leading-[25px]">
								Global virtual collaboration tools for seamless teamwork across
								time zones.
							</li>
						</ul>
					</div>
					<img
						src={wellness}
						alt="wellness"
						className="w-max xl:absolute xl:block hidden bottom-[1%] -left-[7%]"
					/>
					<div className="xl:absolute bottom-[5%] left-[15%] xl:max-w-[440px]">
						<div className="font-bold text-[18px] leading-[25px]">
							Wellness Initiatives:
						</div>
						<ul className="list-disc">
							<li className="text-[18px] leading-[25px]">
								Comprehensive Medical Insurance: Full coverage health plan with
								low deductibles.
							</li>
							<li className="text-[18px] leading-[25px]">
								Employee Wellness Fund: Quarterly allowance for wellness-related
								expenses.
							</li>
						</ul>
					</div>
                </div>
            </div>
        </div>
    );
};
