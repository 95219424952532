import { gql } from "@apollo/client";

export default gql`
    query userWithUsername($username: String $cursor: Int) {
        userWithUsername(username: $username) {
            id
            username
            firstname
            lastname
            tagline
            email
            timestamp
            color
            is_follower
            profile_photo
            followers(first:100){
                data{
                    id
                }paginatorInfo {
                    total
                    lastItem
                    currentPage
                    hasMorePages
                }
            }
            questions(first: 10 page: $cursor orderBy: [{ column: "created_at", order: DESC }]){
                data {
                    id
                    question
                    description
                    created_at
                    voteStatus
                    saveForCurrentUser
                    status
                    slug
                    meta_text
                    tags {
                        id
                        tag_title
                    }
                    categories{
                        id
                        name
                    }
                    invites{
                        id
                        email
                    }
                    attachments {
                        id
                        url
                        type
                        width
                        height
                        public_image
                    }
                    users {
                        id
                        firstname
                        lastname
                        profile_photo
                        color
                        sg_badge
                        sg_paid_user
                        is_follower
                        username
                    }
                    likes(first: 1) {
                        data {
                            users {
                                id
                            }
                        }
                        paginatorInfo {
                            total
                        }
                    }
                    dislikes(first: 1) {
                        data {
                            users {
                                id
                            }
                        }
                        paginatorInfo {
                            total
                        }
                    }
                    answers(first: 1) {
                        data {
                            id
                            answer
                            voteStatus
                            created_at
                            likes(first: 1) {
                                paginatorInfo {
                                    total
                                }
                            }
                            users {
                                id
                                username
                                firstname
                                lastname
                                profile_photo
                                is_pro
                            }
                            comments(first: 1) {
                                paginatorInfo {
                                    total
                                }
                            }
                        }
                        paginatorInfo {
                            total
                        }
                    }
                }
                paginatorInfo{
                    total
                    lastItem
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                }
            }
            answers(first:10 orderBy: [{ column: "created_at", order: DESC }]){
                data{
                    id
                    answer
                    questions{
                        slug
                    }
                    created_at
                    users{
                        id
                        firstname
                        lastname
                        profile_photo
                        color
                    }
                    comments(first:5) {
                        paginatorInfo {
                            total
                        }
                    }
                    likes(first: 1){
                        paginatorInfo{
                            total
                        }
                    }
                    dislikes(first:1){
                        paginatorInfo{
                            total
                        }
                    }
                }
                paginatorInfo{
                    total
                    lastItem
                    currentPage
                    hasMorePages
                    firstItem
                    lastItem
                }
            }
        }
    }
`;
