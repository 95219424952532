import React, { Component } from "react";
import "./styles.css";
import { Knowledgepocket, Loader, Noconnection } from "components";
import Subtract from "assets/images/Subtract-white.png";
import quotes from "assets/images/our-team/quotes.png";
import linkedin from "assets/images/our-team/linkedin.png";
import banner from "assets/images/our-team/our-team-banner.png";
import plus from "assets/images/our-team/plus.png";
import our_team from "../../graphql/queries/ourTeams";
import { Query } from "@apollo/client/react/components";
import { TeamContent } from "./TeamContent";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className="absolute right-0 xl:-right-10 top-1/2 -translate-y-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
			onClick={onClick}
		>
			<BsArrowRight
				size={30}
				strokeWidth={1.2}
				className={onClick !== null ? "primary" : "gray"}
			/>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className="absolute z-10 top-1/2 left-0 xl:-left-10 -translate-y-1/2 flex items-center justify-center cursor-pointer slick-arrows prev"
			onClick={onClick}
		>
			<BsArrowLeft
				size={30}
				strokeWidth={1.2}
				className={onClick !== null ? "primary" : "gray"}
			/>
		</div>
	);
}

class TeamDetail extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	render() {
		const settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			initialSlide: 0,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						infinite: true,
						centerMode: false,
					},
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: false,
					},
				},
				{
					breakpoint: 680,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerMode: false,
					},
				},
			],
		};

		return (
			<>
				<div className="bg-white -mt-[50px]">
					<div className="lg:bg-transparent md:bg-transparent bg-black">
						<div className="lg:hidden md:hidden relative">
							<img
								src={banner}
								alt="banner"
								className="object-cover w-full h-full object-center"
							/>
						</div>
						<div className="lg:h-[90vh] md:h-[100vh] TeamsBanner !lg:bg-[center_center] !bg-left relative">
							<div className="flex w-full z-10 max-w-2xl relative flex-col gap-3 lg:ml-32 md:ml-10 px-10 lg:pt-15 pt-10 pb-4">
								<p className="text-[#FBE4D3] Poppins font-normal">
									About Us - Our Team
								</p>
								<p className="white font-light Poppins lg:text-5xl md:text-5xl text-[30px]">
									The People Behind Procurement League
								</p>
								<div className="h-px bg-white mt-3" />
							</div>
							<div className="-bottom-px z-0 lg:block md:block hidden overflow-hidden absolute left-0 w-full leading-[0]">
								<img
									src={Subtract}
									alt="Subtract"
									className="object-cover w-full left-1/2 -translate-x-1/2 relative"
								/>
							</div>
						</div>
					</div>
					<TeamContent />
					<Query query={our_team}>
						{({ loading, error, data }) => {
							if (loading) {
								return <Loader />;
							}
							if (error) {
								return (
									<div>
										<Noconnection />
									</div>
								);
							}
							return (
								<>
									{data !== undefined && (
										<div className="mt-28 relative">
											<div className="relative container">
												<div className="mb-10">
													<h4 className="lg:text-5xl text-4xl font-light Poppins text-center">
														Meet Our Team Members
													</h4>
												</div>
												<Slider
													{...settings}
													className="px-10"
												>
													{data?.allteams.map((team) => (
														<div
															key={team?.name}
															className="border !w-full max-w-[1200px] rounded-[35px] !mx-auto p-10 md:h-max z-[10]"
														>
															<div className="grid xl:grid-cols-8 gap-5">
																<div className="xl:col-span-2 col-span-8">
																	<img
																		src={team?.image_path}
																		alt={team?.name}
																		className="rounded-full w-[240px] h-[240px] object-cover"
																	/>
																</div>
																<div className="xl:col-span-4 col-span-8 flex flex-col h-max space-y-6">
																	<div className="flex flex-col -space-y-2">
																		<div className="primary Poppins text-[30px] font-[300]">
																			{team?.name}
																		</div>
																		<div className="Poppins text-[30px] font-[300] flex flex-col uppercase leading-10">
																			{team?.title}
																		</div>
																	</div>
																	<div className="Poppins text-[18px] font-[400]">
																		{team?.description}
																	</div>
																	{team?.linkedin && (
																		<a
																			href={team.linkedin}
																			target="_blank"
																			className="flex justify-start items-center space-x-5"
																		>
																			<img
																				src={linkedin}
																				alt="linkedin"
																				className="w-[40px] h-[40px]"
																			/>
																			<div className="Poppins text-[18px] font-[400]">
																				{team?.name}
																			</div>
																		</a>
																	)}
																</div>
																<div className="xl:col-span-2 col-span-8 flex flex-col space-y-4">
																	<img
																		src={quotes}
																		alt="img"
																		className="w-[102px] h-[77px]"
																	/>
																	<div className="Poppins text-[18px] font-[700]">
																		{team?.quote}
																	</div>
																</div>
															</div>
														</div>
													))}
												</Slider>
											</div>
										</div>
									)}
								</>
							);
						}}
					</Query>
					<div className="mt-28 -mb-36 container">
						<Knowledgepocket isImageHide={false} />
					</div>
				</div>
			</>
		);
	}
}

export default TeamDetail;
