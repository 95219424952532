import React, { Component } from "react";
import Slider from "react-slick";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import our_trainers from "../../../../graphql/queries/ourTrainers";
import { Query } from "@apollo/client/react/components";
import { Loader, Noconnection } from "components";
import chris from "../../../../assets/images/team/Chris.png";
import erik from "../../../../assets/images/team/Erik.png";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute right-0 top-1/2 z-10 flex items-center justify-center cursor-pointer slick-arrows next"
            onClick={onClick}
        >
            <BsArrowRight
                size={30}
                strokeWidth={1.2}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="absolute z-10 top-1/2 left-0 flex items-center justify-center cursor-pointer slick-arrows prev"
            onClick={onClick}
        >
            <BsArrowLeft
                size={30}
                strokeWidth={1.2}
                className={onClick !== null ? "primary" : "gray"}
            />
        </div>
    );
}

const staticTrainers = [
    {name: `Chris Shanahan`, image_path: chris,  bio:  `With 20+ years in pharmaceutical procurement, he is Vice President of Global Sustainability Supply Chain at Thermo Fisher Scientific. He turns complex strategies into actionable training, co-authored "The Procurement Leader's Handbook," and leverages real-world case studies for practical insights.`},
    {name: `Erik Stavrand`, image_path: erik, bio:  `Erik has 25+ years of procurement and transformation experience, working with global Fortune 100 and FTSE 50 companies across various industries. He coaches C-suite leaders to align their teams with enterprise strategy and lead more effectively.`},
    {name: `Coming Soon`, image_path: 'ok', image:true, bio:  `New trainers coming soon!`}
]
class trainers extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };

        return (
            <div className="relative blog-posts equal-height">
                <div className="mb-5">
                    <h4 className="lg:text-5xl text-4xl font-light Poppins text-center">
                        {this.props?.title ? this.props?.title : "Our Trainers"}
                    </h4>
                </div>
                <Query query={our_trainers}>
                    {({ loading, error, data }) => {
                        if (loading) {
                            return <Loader />;
                        }
                        if (error) {
                            return (
                                <div>
                                    <Noconnection />
                                </div>
                            );
                        }
                        return (
                            <Slider
                                {...settings}
                                className="important:static lg:px-10 pl-[40px] pr-[14px]"
                            >
                                {(this.props.isBio ? staticTrainers: data?.alltrainers || [])
                                    ?.filter((t) => !!t?.image_path)
                                    .map((team, index) => {
                                        console.log("team :>> ", team);
                                        return (
                                            <div className="h-full p-2 pt-3">
                                                <Link
                                                    to={"/trainners"}
                                                    key={index}
                                                    className="flex flex-col h-full pt-3"
                                                >
                                                    <div className="h-full">
                                                    {
                                                        team?.image ? <div className="w-[233px] h-[167px] bg-[#D9D9D9]" /> : 
                                                        <img
                                                            alt={team.name}
                                                            src={
                                                                team.image_path
                                                            }
                                                            className={`${this.props?.isBio ? 'w-[233px] h-[167px] object-cover':'h-40 object-contain '} grayscale`}
                                                        />
                                                    }
                                                        <h4
                                                            className={`${
                                                                this.props
                                                                    ?.textColor
                                                                    ? this.props
                                                                          ?.textColor
                                                                    : "primary"
                                                            } ${this.props?.isBio ? "pt-5":"mt-3"} text-3xl font-light Poppins`}
                                                        >
                                                            {team.name}
                                                        </h4>
                                                        {this.props?.isBio ? (
                                                            <p className="black Poppins pt-5 text-lg font-normal">
                                                                {team.bio}
                                                            </p>
                                                        ) : (
                                                            <>
                                                                <p className="black Poppins text-3xl font-light">
                                                                    {team.title}
                                                                </p>
                                                                <p className="mt-3 line-clamp-3 font-semibold text-lg text-new-color text-left Poppins">
                                                                    Company:{" "}
                                                                    {
                                                                        team.company
                                                                    }
                                                                </p>
                                                                <BsArrowRight
                                                                    size={30}
                                                                    className="primary"
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                            </Slider>
                        );
                    }}
                </Query>
            </div>
        );
    }
}

export default trainers;
