import React, { useEffect, useState } from "react";
import { RiMessage3Fill } from "react-icons/ri";
import { TiMessages } from "react-icons/ti";
import {
  ProfileAvatar,
  Noconnection,
  Postfooter,
  Postskeleton, Pagenotfound,
} from "components";
import GET_USER_WITH_USERNAME from "../../../graphql/queries/getUserWithUsername";
import { Query } from "@apollo/client/react/components";
import { seo } from "helpers/seo";
import { connect, useSelector } from "react-redux";
import { Tab } from "@headlessui/react";
import QuickActions from "../../../components/allcomponents/quickActions";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";
import { FaUserMinus } from "react-icons/fa";
import { BsChatLeft } from "react-icons/bs";
import Api from "services/api";
import encryptfunction from "helpers/Encrypt";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function UserProfile(props) {
  const [username, setUsername] = useState(props.authUser);
  const [questionsToShow, setQuestionsToShow] = useState(10);
  const [answersToShow, setAnswersToShow] = useState(10);
  const userInfo = useSelector(
    (state) => state.AuthUserReducer?.authUserInformation
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    seo({
      title: ` ${props.username} | Procurement League`,
    });
  }, []);

  let variables = {};
  variables.username = props.username;
  const handleLoadMoreQuestions = () => {
    setQuestionsToShow(questionsToShow + 10);
  };

  const handleLoadMoreAnswers = () => {
    setAnswersToShow(answersToShow + 10);
  };

  const startChat = async (id) => {
    await Api.startChatMessages(id)
  }

  return (
    <div className="container">
      <Query query={GET_USER_WITH_USERNAME} variables={variables}>
        {({ loading, error, data, fetchMore, refetch }) => {

          if (loading) {
            return <Postskeleton />;
          }
          if (error) {
            return (
              <div>
                <Noconnection />
              </div>
            );
          }
          if (data !== undefined) {
            let user = data && data.userWithUsername;
            // console.log("hasMorePages",user.questions.paginatorInfo.hasMorePages)
            return (
              <>
                {user ? (
                  <div className="grid gap-4 lg:grid-cols-7">
                    <div className="lg:col-span-2">
                      <div className="px-3 py-5 mb-3 border rounded-xl">
                        <div className="w-40 h-40 mx-auto rounded-full">
                          <ProfileAvatar
                            singleUser={user}
                            className={"rounded-full object-cover"}
                            sizeStyle={{ width: "100%", height: "100%" }}
                            platform={"PL"}
                          />
                        </div>
                        <h2 className="my-3 text-xl text-center Medium lg:text-2xl">
                          {user.firstname ? user?.firstname : "---"}{" "}
                          {user?.lastname ? user?.lastname : "---"}
                        </h2>
                        <p className="text-center text-color">
                          {user?.tagline ? user?.tagline : "---"}
                        </p>
                        <button
                          onClick={true}
                          className="flex items-center justify-center w-full h-10 mt-3 border-0 shadow-none darkGray hover:black focus:black hover:opacity-70 Regular bg--lightGray hover:bg--lightGray focus:bg--lightGray rounded--xl"
                        >
                          Total Followers
                          <FaUserMinus className="ml-2" size={20} />
                          {user?.followers?.paginatorInfo?.total}
                        </button>
                        <Link
                          onClick={()=>startChat(Number(user?.id))}
                          to={`/chat/${encryptfunction(userInfo?.id)}/${encryptfunction(user?.id)}`}
                          className="flex items-center justify-center w-full h-10 mt-3 border-0 shadow-none darkGray hover:black focus:black hover:opacity-70 Regular bg--lightGray hover:bg--lightGray focus:bg--lightGray rounded--xl"
                        >
                          Chat
                          <BsChatLeft className="ml-2" size={20} />
                        </Link>
                      </div>
                    </div>
                    <div className="lg:col-span-5">
                      <div className="p-8 border rounded-xl">
                        <Tab.Group>
                          <Tab.List className="flex md:flex-nowrap flex-wrap max-w-full p-2 mx-auto mb-10 bg-gray-100 lightPrimary lg:max-w-lg rounded-xl">
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "flex-grow p-0 m-0 flex-shrink-0 bg-gray-200 Regular px-4 hover:bg--lightPrimary hover:opacity-70 cursor-pointer md:rounded-l-full h-10 flex items-center justify-center",
                                  selected
                                    ? "bg--primary text-white"
                                    : "bg--lightPrimary primary"
                                )
                              }
                            >
                              {user?.questions?.paginatorInfo.total} Questions
                              <RiMessage3Fill className="ml-2" />
                            </Tab>
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "flex-grow p-0 m-0 flex-shrink-0 bg--lightPrimary Regular px-4 hover:bg--lightPrimary hover:opacity-70 cursor-pointer md:rounded-r-full h-10 flex items-center justify-center",
                                  selected
                                    ? "bg--primary text-white"
                                    : "bg--lightPrimary primary"
                                )
                              }
                            >
                              {user.answers.paginatorInfo.total} Answers
                              <TiMessages className="ml-2" />
                            </Tab>
                          </Tab.List>

                          <Tab.Panels className="mt-2">
                            <Tab.Panel className="bg-white rounded-xl">
                              <>
                                {user?.questions?.data.slice(0, questionsToShow).map((data) => (
                                  <div key={data.id}>

                                    <p className="font-bold capitalize text-[18px]">{data?.description}</p>
                                    <div className="flex justify-between items-center flex-wrap">

                                      <Link to={`/discussions/${data.slug}`}>
                                        <div dangerouslySetInnerHTML={{ __html: data.question }} />
                                      </Link>

                                      <div className="text-right">
                                        <QuickActions data={data} type="question" key={data.id} />
                                      </div>
                                    </div>
                                    <div className="py-3">
                                      <Postfooter
                                        question={data}
                                        type="question"
                                        answers={data.answers}
                                        likes={data.likes}
                                        key={data.id}
                                      />
                                    </div>
                                    <div className="my-4 divider" />
                                  </div>
                                ))}

                                {/* Load More button for questions */}
                                {user?.questions?.data.length > questionsToShow && (
                                  <div className="flex justify-center items-center w-full">
                                    <button onClick={handleLoadMoreQuestions} className="w-max mt-4 py-2 px-3 bg--primary text-white hover:opacity-70 text-center">
                                      Load More Questions
                                    </button>
                                  </div>
                                )}
                              </>
                            </Tab.Panel>
                            <Tab.Panel className="p-3 bg-white rounded-xl">
                              <div>
                                {user?.answers.data.slice(0, answersToShow).map((data) => (
                                  <div key={data.id}>
                                    <div className="text-right">
                                      <QuickActions data={data} type="answer" key={data.answer?.id} />
                                    </div>
                                    <Link
                                      to={`/discussions/${data?.questions?.slug}`}
                                    >
                                      <div dangerouslySetInnerHTML={{ __html: data.answer }} />
                                    </Link>
                                    <div className="flex gap-3 items-center flex-wrap my-3">
                                      <p className="gray Poppins flex-shrink-0">
                                        {data?.comments.paginatorInfo.total} comments
                                      </p>
                                      <p className="gray Poppins flex-shrink-0">Posted on {data?.created_at}</p>
                                    </div>
                                    <div className="my-4 divider" />
                                  </div>
                                ))}

                                {/* Load More button for answers */}
                                {user?.answers.data.length > answersToShow && (
                                  <div className="flex justify-center items-center w-full">
                                    <button onClick={handleLoadMoreAnswers} className="w-max mt-4 py-2 px-3 bg--primary text-white hover:opacity-70 text-center">
                                      Load More Answers
                                    </button>
                                  </div>
                                )}
                              </div>
                            </Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Alert severity="error">This user is no longer available</Alert>
                )}
              </>
            );

          } else {
            return <Pagenotfound />
          }
        }}
      </Query>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(UserProfile);
