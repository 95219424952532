import React, { Component } from "react";
import { Buttonlink, Signinpopup } from "components";
import { Popup } from "semantic-ui-react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiCheck, FiX } from "react-icons/fi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ShowMoreText from "react-show-more-text";
import { Link } from "react-router-dom";
import "../../../../assets/css/semantic.css";
import Auth from "../../../../Auth";
import payment_one from '../../../../assets/images/masterClasses/payment_one.png'
import payment_two from '../../../../assets/images/masterClasses/payment_two.png'
import { connect } from "react-redux";
import { BsArrowDown, BsPlusLg } from "react-icons/bs";
const auth = new Auth();

class paymentplan extends Component {
  constructor(props) {
    super(props);
    this.openSignIn = React.createRef();
    this.state = {
      open: false,
      logged_in: false,
      singlePackage: true,
      singlePackage2: true,
    };
  }
  singlePackageClick = () => {
    this.setState({ singlePackage: false });
    this.setState({ singlePackage2: true });
  };
  prevSinglePackageClick = () => {
    this.setState({ singlePackage: true });
    this.setState({ singlePackage2: false });
  };
  openPopup = () => {
    this.openSignIn.current.openPopup();
  };
  render() {
    const { plans } = this.props;
    const currentPlan = [];
    if (this.props.authUser.subscriptionsPurchased) {
      this.props.authUser.subscriptionsPurchased.data.map((PlanId) => {
        currentPlan.push(parseInt(PlanId.user_account_id));
      });
    }

    const selected = (plan_id) => {
      if (currentPlan) {
        return currentPlan.indexOf(plan_id) > -1;
      } else {
        return this.props.authUser.is_pro;
      }
    };

    return (
      <div className="relative blog-posts">
        {this.props.location.pathname === "/classes" ? (
          <div className="relative blog-posts">
            <div className="container">
              <h4 className="lg:text-5xl text-4xl font-light Poppins text-center">
                Choose Your Plan
              </h4>
            </div>
            <div className="mt-10 grid lg:grid-cols-2 gap-5 md:grid-cols-2 grid-cols-1 relative">
              {plans.map((plan, index) => (
                <div
                  className="w-full z-10 flex flex-col overflow-hidden shadow-[0px_2px_10px_0px_#0000001A] max-w-[530px] mx-auto rounded-xl bg--lightBlue"
                  key={plan.id}>
                  <Link to={"/pricing"} className="block">
                    <div className="flex flex-wrap gap-5 items-center justify-center pt-10">
                      {
                        index === 0 ?
                          <img
                            alt={plan.name}
                            src={payment_one}
                            className="object-contain object-top w-full h-32"
                          /> :
                          <img
                            alt={plan.name}
                            src={payment_two}
                            className="object-contain object-top w-full h-32"
                          />
                      }
                      {/* <p className="my-3 Poppins">All Access</p> */}
                      <h4 className="my-3 Poppins font-light text-[35px] text-center primary">{plan.name}</h4>
                    </div>
                    <div className="px-5 mt-3">
                      <div className="flex justify-center items-baseline mb-3 capitalize ">
                        {/* <BsCurrencyDollar size={28} /> */}
                        <p className="text-4xl Poppins">{plan.price}.00</p>
                        <p className="text-lg black font-semibold Poppins">USD</p>
                        <p className="text-lg black Poppins font-semibold">
                          /{plan.validity === "M" ? "month" : "year"}
                        </p>
                      </div>
                    </div>
                    {
                      index === 0 ?
                        <div className="flex px-5 flex-col mt-10 gap-1">
                          <p className="text-lg font-semibold black">Benefits of All Access Basic</p>
                          <BsPlusLg className="primary" size={24} />
                          <p className="text-lg font-semibold black">Handouts & Case Studies</p>
                          <p className="text-lg font-semibold black">Certificate of Completion</p>
                          <p className="text-lg font-semibold black">Special Classess On Discount</p>
                          <p className="text-lg font-semibold black">Free invitation to Live Class</p>
                        </div>
                        :
                        <div className="flex px-5 flex-col mt-10 gap-1">
                          <p className="text-lg font-semibold black">Benefits of All Access Basic</p>
                          <BsPlusLg className="primary" size={24} />
                          <p className="text-lg font-semibold black flex items-center gap-1"><p className="primary text-lg font-bold">#loveprocurement</p> Giftbox</p>
                          <p className="text-lg font-semibold black">Receive Weekly Job Updates</p>
                          <p className="text-lg font-semibold black">1:1 Expert Session</p>
                        </div>
                    }
                  </Link>
                  <div className="p-5 flex-grow flex flex-col">
                    <div className="bg-black/50 h-px w-3/4 mb-4" />
                    <p className="black">
                      <ShowMoreText
                        lines={3}
                        more="Read more"
                        less="Show less"
                        className="black text-lg Poppins font-normal wrapper-class"
                        anchorClass="primary"
                        expanded={false}
                        truncatedEndingComponent={"... "}>
                        {plan.description}
                      </ShowMoreText>
                    </p>
                    <div className="flex-grow flex items-end">
                      {auth.isAuthenticated() ? (
                        this.props.authUser &&
                          this.props.authUser.is_pro ? null : (
                          <Buttonlink
                            to={{
                              pathname: `/payment`,
                              state: {
                                item: {
                                  id: plan.id,
                                  price: plan.price,
                                  name: plan.name,
                                  is_shippable: false,
                                },
                                type: "subscription",
                              },
                            }}
                            Lasticon={<BsArrowDown size={23} />}
                            Text="Select Plan"
                            className="flex items-center px-3 justify-between mt-3 rounded-full lg:w-48 md:w-60 w-full Poppins white bg--primary hover:color-white hover:bg--primary focus:bg--primary hover:opacity-70 h-11"
                          />
                        )
                      ) : (
                        <Buttonlink
                          to={{
                            pathname: `/payment`,
                            state: {
                              item: {
                                id: plan.id,
                                price: plan.price,
                                name: plan.name,
                                is_shippable: false,
                              },
                              type: "subscription",
                            },
                          }}
                          Lasticon={<BsArrowDown size={23} />}
                          Text="Select Plan"
                          className="flex items-center px-3 justify-between mt-3 rounded-full lg:w-48 md:w-60 w-full Poppins white bg--primary hover:color-white hover:bg--primary focus:bg--primary hover:opacity-70 h-11"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="absolute top-1/4 -left-16 lg:block hidden">
                <svg width="258" height="258" viewBox="0 0 258 258" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M258 129C258 200.245 200.245 258 129 258C57.7553 258 0 200.245 0 129C0 57.7553 57.7553 0 129 0C200.245 0 258 57.7553 258 129Z" fill="#FFFAEF" />
                </svg>
              </div>
              <div className="absolute top-1/4 -right-32 lg:block hidden">
                <svg width="214" height="214" viewBox="0 0 214 214" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_490_933)">
                    <path d="M90.1865 29.3941L85.6539 81.6878L33.3602 77.1551C20.2868 76.0219 9.63191 84.9771 8.49874 98.0505C7.36558 111.124 16.3208 121.779 29.3942 122.912L81.6878 127.445L77.1551 179.738C76.022 192.812 84.9771 203.467 98.0506 204.6C111.124 205.733 121.779 196.778 122.912 183.704L127.445 131.411L179.738 135.943C192.812 137.077 203.467 128.121 204.6 115.048C205.733 101.975 196.778 91.3197 183.704 90.1865L131.411 85.6538L135.943 33.3602C137.077 20.2868 128.121 9.63187 115.048 8.49871C101.975 7.36554 91.3197 16.3207 90.1865 29.3941Z" fill="#B5E2FF" fillOpacity="0.4" />
                  </g>
                  <defs>
                    <clipPath id="clip0_490_933">
                      <rect width="196.836" height="196.836" fill="white" transform="translate(0 196.101) rotate(-85.0461)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="overflow-hidden rounded-2xl shadow-[0px_4px_40px_#0000001a] p-10">
              <h2 className="lg:text-5xl text-4xl text-center font-light Poppins">
                Choose Your Learning & Development Plans
              </h2>
              <div className="grid grid-cols-3 mt-16 lg:grid-cols-4">
                <div className="col-span-2 py-4">
                  <div className="relative flex items-center h-16">
                    <h1 className="hidden text-lg Poppins lg:text-3xl lg:flex">
                      Plan Features & Pricing
                    </h1>
                    <h1 className="flex text-lg Poppins lg:text-3xl lg:hidden">
                      Plan Features
                    </h1>
                    {this.state.singlePackage === false ? (
                      <div
                        onClick={this.prevSinglePackageClick}
                        className="absolute right-5 block lg:hidden">
                        <IoIosArrowBack className="primary" size={24} />
                        <p className="fs-12 primary">prev</p>
                      </div>
                    ) : null}
                    {this.state.singlePackage === true ? (
                      <div
                        onClick={this.singlePackageClick}
                        className="absolute right-5 block lg:hidden">
                        <IoIosArrowForward className="primary" size={24} />
                        <p className="fs-12 primary">next</p>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Validity
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        className="md:w-full w-5"
                        content="The period for which your ProcureClass Subscription will be valid."
                        inverted
                      />
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Hangouts
                      </h4>
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Case Studies
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        content="Case studies written by professors and other leading business executives worldwide, focusing on procurement problems and decisions companies face."
                        inverted
                        className="md:w-full w-5"
                      />
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Certification of completion
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        content="Receive a certificate of completion and showcase the skills you acquired."
                        inverted
                        className="md:w-full w-5"
                      />
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Special classes on discount
                      </h4>
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Free invitation to live classes
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        content="Receive invites for our upcoming ProcureClass free of cost. These Live Classes will be on Zoom, and you get a chance to interact with the instructor as well."
                        inverted
                        className="md:w-full w-5"
                      />
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Add extra 20% Discount for all classes
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        content="Opt for the Annual Plan and enjoy an additional 20% Discount on the Subscription Charges"
                        inverted
                        className="md:w-full w-5"
                      />
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Weekly Job Updates
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        content="Receive weekly job updates you can easily apply to on your registered email."
                        inverted
                        className="md:w-full w-5"
                      />
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Giftbox
                      </h4>
                    </div>
                    <div className="flex items-center h-12 lg:h-16 border-b">
                      <h4 className="mr-1 text-sm Poppins lg:text-lg text-color">
                        Expert Session
                      </h4>
                      <Popup
                        trigger={
                          <AiOutlineQuestionCircle
                            className="cursor-pointer gray"
                            size={16}
                          />
                        }
                        content="Request A Mentor/ Industry Expert Session To Make Advances In Your Current Job or Plan Your Next Job Move"
                        inverted
                        className="md:w-full w-5"
                      />
                    </div>
                  </div>
                </div>
                {this.state.singlePackage ? (
                  <div className="relative lg:mr-4 bg-[#D9D9D9]/15 py-4">
                    <div className="flex flex-col items-center justify-center h-16">
                      <h4 className="Poppins lg:text-3xl text-center text-lg font-semibold">
                        Pro (Monthly)
                      </h4>
                      <p className="text-sm text-black Poppins lg:text-lg">
                        ${plans[0].price}.00/Month
                      </p>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <p className="text-lg">1 month</p>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                        <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                        <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                        <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                        <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                        <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                        <path d="M13.2799 0C16.9366 0 20.2084 1.44341 22.6141 3.84929C25.0198 6.25501 26.4634 9.6231 26.4634 13.2799C26.4634 16.9366 25.02 20.2084 22.6141 22.6141C20.2084 25.0198 16.9366 26.4634 13.2799 26.4634C9.6231 26.4634 6.25501 25.02 3.84928 22.6141C1.44356 20.2084 0 16.9366 0 13.2799C0 9.6231 1.44341 6.25501 3.84928 3.84929C6.25501 1.44356 9.6231 0 13.2799 0ZM21.2669 5.29263C19.246 3.17558 16.3591 1.92453 13.2799 1.92453C10.1043 1.92453 7.31353 3.17551 5.29285 5.29263C3.1758 7.31347 1.92475 10.1041 1.92475 13.2796C1.92475 16.3591 3.17573 19.246 5.29285 21.2667C7.31369 23.2875 10.1043 24.5384 13.2799 24.5384C16.3593 24.5384 19.2462 23.2874 21.2669 21.2667C23.2877 19.2458 24.5386 16.3589 24.5386 13.2796C24.5386 10.1041 23.2876 7.31331 21.2669 5.29263Z" fill="#2B478B" />
                        <path d="M16.9381 8.17953C17.323 7.79465 17.9004 7.79465 18.2853 8.17953C18.6701 8.56442 18.6701 9.14184 18.2853 9.52673L14.5323 13.2796L18.2853 16.9364C18.6701 17.3213 18.6701 17.9949 18.2853 18.3798C17.9004 18.7647 17.323 18.7647 16.9381 18.3798L13.1852 14.6269L9.62476 18.1873C9.23987 18.5722 8.56623 18.5722 8.18135 18.1873C7.89268 17.8024 7.89268 17.225 8.18135 16.8401L11.8381 13.2797L8.18135 9.71931C7.89268 9.33443 7.89268 8.66079 8.18135 8.2759C8.56623 7.89102 9.23987 7.89102 9.62476 8.2759L13.1852 11.8363L16.9381 8.17953Z" fill="#2B478B" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                        <path d="M13.2799 0C16.9366 0 20.2084 1.44341 22.6141 3.84929C25.0198 6.25501 26.4634 9.6231 26.4634 13.2799C26.4634 16.9366 25.02 20.2084 22.6141 22.6141C20.2084 25.0198 16.9366 26.4634 13.2799 26.4634C9.6231 26.4634 6.25501 25.02 3.84928 22.6141C1.44356 20.2084 0 16.9366 0 13.2799C0 9.6231 1.44341 6.25501 3.84928 3.84929C6.25501 1.44356 9.6231 0 13.2799 0ZM21.2669 5.29263C19.246 3.17558 16.3591 1.92453 13.2799 1.92453C10.1043 1.92453 7.31353 3.17551 5.29285 5.29263C3.1758 7.31347 1.92475 10.1041 1.92475 13.2796C1.92475 16.3591 3.17573 19.246 5.29285 21.2667C7.31369 23.2875 10.1043 24.5384 13.2799 24.5384C16.3593 24.5384 19.2462 23.2874 21.2669 21.2667C23.2877 19.2458 24.5386 16.3589 24.5386 13.2796C24.5386 10.1041 23.2876 7.31331 21.2669 5.29263Z" fill="#2B478B" />
                        <path d="M16.9381 8.17953C17.323 7.79465 17.9004 7.79465 18.2853 8.17953C18.6701 8.56442 18.6701 9.14184 18.2853 9.52673L14.5323 13.2796L18.2853 16.9364C18.6701 17.3213 18.6701 17.9949 18.2853 18.3798C17.9004 18.7647 17.323 18.7647 16.9381 18.3798L13.1852 14.6269L9.62476 18.1873C9.23987 18.5722 8.56623 18.5722 8.18135 18.1873C7.89268 17.8024 7.89268 17.225 8.18135 16.8401L11.8381 13.2797L8.18135 9.71931C7.89268 9.33443 7.89268 8.66079 8.18135 8.2759C8.56623 7.89102 9.23987 7.89102 9.62476 8.2759L13.1852 11.8363L16.9381 8.17953Z" fill="#2B478B" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                        <path d="M13.2799 0C16.9366 0 20.2084 1.44341 22.6141 3.84929C25.0198 6.25501 26.4634 9.6231 26.4634 13.2799C26.4634 16.9366 25.02 20.2084 22.6141 22.6141C20.2084 25.0198 16.9366 26.4634 13.2799 26.4634C9.6231 26.4634 6.25501 25.02 3.84928 22.6141C1.44356 20.2084 0 16.9366 0 13.2799C0 9.6231 1.44341 6.25501 3.84928 3.84929C6.25501 1.44356 9.6231 0 13.2799 0ZM21.2669 5.29263C19.246 3.17558 16.3591 1.92453 13.2799 1.92453C10.1043 1.92453 7.31353 3.17551 5.29285 5.29263C3.1758 7.31347 1.92475 10.1041 1.92475 13.2796C1.92475 16.3591 3.17573 19.246 5.29285 21.2667C7.31369 23.2875 10.1043 24.5384 13.2799 24.5384C16.3593 24.5384 19.2462 23.2874 21.2669 21.2667C23.2877 19.2458 24.5386 16.3589 24.5386 13.2796C24.5386 10.1041 23.2876 7.31331 21.2669 5.29263Z" fill="#2B478B" />
                        <path d="M16.9381 8.17953C17.323 7.79465 17.9004 7.79465 18.2853 8.17953C18.6701 8.56442 18.6701 9.14184 18.2853 9.52673L14.5323 13.2796L18.2853 16.9364C18.6701 17.3213 18.6701 17.9949 18.2853 18.3798C17.9004 18.7647 17.323 18.7647 16.9381 18.3798L13.1852 14.6269L9.62476 18.1873C9.23987 18.5722 8.56623 18.5722 8.18135 18.1873C7.89268 17.8024 7.89268 17.225 8.18135 16.8401L11.8381 13.2797L8.18135 9.71931C7.89268 9.33443 7.89268 8.66079 8.18135 8.2759C8.56623 7.89102 9.23987 7.89102 9.62476 8.2759L13.1852 11.8363L16.9381 8.17953Z" fill="#2B478B" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-12 border-b lg:h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                        <path d="M13.2799 0C16.9366 0 20.2084 1.44341 22.6141 3.84929C25.0198 6.25501 26.4634 9.6231 26.4634 13.2799C26.4634 16.9366 25.02 20.2084 22.6141 22.6141C20.2084 25.0198 16.9366 26.4634 13.2799 26.4634C9.6231 26.4634 6.25501 25.02 3.84928 22.6141C1.44356 20.2084 0 16.9366 0 13.2799C0 9.6231 1.44341 6.25501 3.84928 3.84929C6.25501 1.44356 9.6231 0 13.2799 0ZM21.2669 5.29263C19.246 3.17558 16.3591 1.92453 13.2799 1.92453C10.1043 1.92453 7.31353 3.17551 5.29285 5.29263C3.1758 7.31347 1.92475 10.1041 1.92475 13.2796C1.92475 16.3591 3.17573 19.246 5.29285 21.2667C7.31369 23.2875 10.1043 24.5384 13.2799 24.5384C16.3593 24.5384 19.2462 23.2874 21.2669 21.2667C23.2877 19.2458 24.5386 16.3589 24.5386 13.2796C24.5386 10.1041 23.2876 7.31331 21.2669 5.29263Z" fill="#2B478B" />
                        <path d="M16.9381 8.17953C17.323 7.79465 17.9004 7.79465 18.2853 8.17953C18.6701 8.56442 18.6701 9.14184 18.2853 9.52673L14.5323 13.2796L18.2853 16.9364C18.6701 17.3213 18.6701 17.9949 18.2853 18.3798C17.9004 18.7647 17.323 18.7647 16.9381 18.3798L13.1852 14.6269L9.62476 18.1873C9.23987 18.5722 8.56623 18.5722 8.18135 18.1873C7.89268 17.8024 7.89268 17.225 8.18135 16.8401L11.8381 13.2797L8.18135 9.71931C7.89268 9.33443 7.89268 8.66079 8.18135 8.2759C8.56623 7.89102 9.23987 7.89102 9.62476 8.2759L13.1852 11.8363L16.9381 8.17953Z" fill="#2B478B" />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-20 lg:h-20">
                      {auth.isAuthenticated() ? (
                        !this.props.authUser ? null : (
                          <Buttonlink
                            to={{
                              pathname: `/payment`,
                              state: {
                                item: {
                                  id: plans[0].id,
                                  price: plans[0].price,
                                  name: plans[0].name,
                                  data_type: "COURSE",
                                  is_shippable: false,
                                },
                                type: "subscription",
                              },
                            }}
                            Lasticon={<BsArrowDown size={24} />}
                            Text={selected(6) ? "Downgrade" : "Select"}
                            className="flex items-center justify-between flex-grow w-full mx-3 mt-5 h-12 px-5 text-white border-0 shadow-none bg--primary hover:text-white Poppins hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                          />
                        )
                      ) : (
                        <Buttonlink
                          to={{
                            pathname: `/payment`,
                            state: {
                              item: {
                                id: plans[0].id,
                                price: plans[0].price,
                                name: plans[0].name,
                                data_type: "COURSE",
                                is_shippable: false,
                              },
                              type: "subscription",
                            },
                          }}
                          Lasticon={<BsArrowDown size={24} />}
                          Text={"Select"}
                          className="flex items-center justify-between flex-grow w-full mx-3 mt-5 h-12 px-5 text-white border-0 shadow-none bg--primary hover:text-white Poppins hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                        />
                      )}
                    </div>
                  </div>
                ) : null}
                {this.state.singlePackage2 ? (
                  <>
                    <div
                      className={`lg:block hidden flex-col bg-[#D9D9D9]/15 py-4
                        ${this.state.singlePackage === false ? "!flex" : null
                        } `}>
                      <div className="flex flex-col items-center justify-center h-16">
                        <h4 className="text-center Poppins font-semibold lg:text-3xl text-lg">
                          Pro (Yearly)
                        </h4>
                        <p className="text-sm text-black lg:text-lg">
                          ${plans[1].price}.00/Year
                        </p>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <p className="text-lg">12 months</p>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-12 border-b lg:h-16">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                          <path d="M24.6058 2.60686C24.3931 2.39405 24.1046 2.27441 23.8038 2.27441C23.503 2.27441 23.2145 2.39405 23.0018 2.60686L12.4689 13.1398L8.73691 9.40777C8.4504 9.12126 8.03283 9.00937 7.64142 9.11424C7.25004 9.21912 6.94433 9.52483 6.83946 9.91621C6.73458 10.3076 6.84647 10.7252 7.13298 11.0117L11.6669 15.5456C11.8795 15.7584 12.1681 15.8781 12.4689 15.8781C12.7697 15.8781 13.0582 15.7584 13.2709 15.5456L24.6057 4.21079C24.8185 3.99816 24.9382 3.70962 24.9382 3.40882C24.9382 3.10802 24.8185 2.81948 24.6057 2.60686H24.6058Z" fill="#EF6822" />
                          <path d="M24.6875 9.97734C24.6065 9.5805 24.3199 9.25701 23.9357 9.12869C23.5515 9.00037 23.1281 9.08681 22.8249 9.35533C22.5217 9.62395 22.3848 10.0339 22.4658 10.4307C22.9328 12.7113 22.6058 15.0832 21.539 17.1523C20.4722 19.2214 18.7299 20.8633 16.601 21.8057C14.4724 22.748 12.0855 22.9339 9.83648 22.3326C7.5875 21.7313 5.61188 20.3788 4.23777 18.4998C2.86344 16.6208 2.17308 14.3283 2.28137 12.003C2.38966 9.67762 3.29006 7.45913 4.8329 5.71616C6.37585 3.97305 8.46855 2.80995 10.7636 2.42031C13.0587 2.03058 15.418 2.43747 17.45 3.57354C17.8043 3.77195 18.2376 3.76624 18.5866 3.5586C18.9356 3.35096 19.1474 2.97284 19.142 2.5668C19.1367 2.16075 18.9151 1.78835 18.5608 1.58994C16.1725 0.252313 13.4103 -0.262124 10.7006 0.125953C7.99083 0.514125 5.48418 1.78311 3.56741 3.73729C1.65049 5.69146 0.430012 8.22211 0.0942169 10.9387C-0.241649 13.6553 0.325932 16.4071 1.70932 18.7692C3.09259 21.1313 5.21486 22.9725 7.74843 24.0087C10.282 25.045 13.0864 25.2186 15.7284 24.5029C18.3706 23.7872 20.7037 22.2218 22.3678 20.0483C24.0321 17.8749 24.9347 15.2142 24.9366 12.4768C24.9361 11.6375 24.8526 10.8003 24.6872 9.9775L24.6875 9.97734Z" fill="#EF6822" />
                        </svg>
                      </div>
                      <div className="flex items-center justify-center h-20 lg:h-20">
                        {auth.isAuthenticated() ? (
                          !this.props.authUser ? null : (
                            <Buttonlink
                              to={{
                                pathname: `/payment`,
                                state: {
                                  item: {
                                    id: plans[1].id,
                                    price: plans[1].price,
                                    name: plans[1].name,
                                    data_type: "COURSE",
                                    is_shippable: false,
                                  },
                                  type: "subscription",
                                },
                              }}
                              Lasticon={<BsArrowDown size={24} />}
                              Text={selected(5) ? "Upgrade" : "Select"}
                              className="flex items-center justify-between mx-3 mt-5 flex-grow w-full h-12 px-5 text-white border-0 shadow-none bg--primary hover:text-white Poppins hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                            />
                          )
                        ) : (

                          <Buttonlink
                            to={{
                              pathname: `/payment`,
                              state: {
                                item: {
                                  id: plans[1].id,
                                  price: plans[1].price,
                                  name: plans[1].name,
                                  data_type: "COURSE",
                                  is_shippable: false,
                                },
                                type: "subscription",
                              },
                            }}
                            Lasticon={<BsArrowDown size={24} />}
                            Text={"Select"}
                            className="flex items-center justify-between mx-3 mt-5 flex-grow w-full h-12 px-5 text-white border-0 shadow-none bg--primary hover:text-white Poppins hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full"
                          />
                          // <div
                          //   onClick={this.openPopup}
                          //   className="flex items-center justify-center flex-grow w-full h-12 px-5 text-white border-0 shadow-none bg--primary Poppins hover:bg--primary hover:opacity-70 focus:bg--primary rounded--full">
                          //   Select
                          // </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        )}
        <Signinpopup ref={this.openSignIn} protect={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.AuthUserReducer.authUserInformation,
});

export default connect(mapStateToProps, null)(paymentplan);
