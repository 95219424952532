import { gql } from "@apollo/client";

export default gql`
	mutation EnrollInSellerContent($sellerContentId: ID!, $tokens: Int!) {
		enrollInSellerContent(sellerContentId: $sellerContentId, tokens: $tokens) {
			success
			message
		}
	}
`;
